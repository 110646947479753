import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Pageable } from "../model/pageable";
import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { User } from "../model/user";
import { Customer } from "../model/customer";
import { CustomerRelated } from "../model/customer_related";
import { Page } from "../model/page";
import { Observable } from "rxjs";

interface userSearchFilter {
  name: string;
}

@Injectable({
  providedIn: "root",
})
export class UserService extends BaseService<User> {
  private userLogged?: User;

  constructor(protected override http: HttpClient) {
    super(http);
  }

  search(user: userSearchFilter, page: Pageable): Observable<Page<User>> {
    return this.get(
      `${environment.iam}/iam/users`,
      new HttpParams()
        .set("sort", page.sort)
        .set("page", page.offset)
        .set("pageSize", page.limit)
        .set("name", user.name)
    );
  }

  remove(id: string): Observable<void> {
    return this.delete(`${environment.iam}/iam/users/${id}`);
  }

  create(user: User): Observable<User> {
    return this.post(`${environment.iam}/iam/users`, user);
  }

  setUserLogged() {
    this.loadUserLogged().subscribe({
      next: (user: User) => {
        this.userLogged = user;
      },
      error: (e) => {
        console.log(e);
      },
    });
  }

  getUserLogged(): User {
    return this.userLogged!;
  }

  loadUserLogged(): Observable<User> {
    return this.get(`${environment.iam}/iam/users/me`, new HttpParams());
  }

  load(id: string): Observable<User> {
    return this.get(`${environment.iam}/iam/users/${id}`);
  }

  update(user: User): Observable<User> {
    return this.put(`${environment.iam}/iam/users/${user.id}`, user);
  }
  /**
   * Load all customers related to the user
   */
  loadUserCustomers(
    user: User,
    page: Pageable
  ): Observable<Page<CustomerRelated>> {
    let params = new HttpParams()
      .set("page", page.offset | 1)
      .set("pageSize", page.limit);

    return this.get(
      `${environment.iam}/iam/users/${user.id}/customers`,
      params
    );
  }
  /**
   * save user customers
   */
  saveUserCustomers(
    user: User,
    customers: CustomerRelated[]
  ): Observable<CustomerRelated[]> {
    return this.post(
      `${environment.iam}/iam/users/${user.id}/customers`,
      customers
    );
  }
  /**
   * update user customers
   */
  updateUserCustomers(
    user: User,
    customer: CustomerRelated
  ): Observable<CustomerRelated> {
    return this.put(
      `${environment.iam}/iam/users/${user.id}/customers/${customer.id}`,
      customer
    );
  }
  /**
   * update user customers
   */
  removeUserCustomers(user: User, customerId: string): Observable<void> {
    return this.delete(
      `${environment.iam}/iam/users/${user.id}/customers/${customerId}`
    );
  }
}
