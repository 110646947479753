import { CommonModule } from '@angular/common';
import { StudyService } from './../../../services/study.service';
import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { Study } from '../../../model/study';
import { Observable, Subscription } from 'rxjs';
import { DashboardTabResultsItemComponent } from './dashboard-tab-results-item/dashboard-tab-results-item.component';
import {MatSort} from "@angular/material/sort";
import { Pageable } from '../../../model/pageable';
import {Page} from "../../../model/page"
import {MatPaginator, PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-dashboard-tab-results',
  standalone: true,
  imports: [

    DashboardTabResultsItemComponent,
    CommonModule
  ],
  templateUrl: './dashboard-tab-results.component.html',
  styleUrl: './dashboard-tab-results.component.scss'
})

export class DashboardTabResultsComponent {
  @Input() studies$: Observable<Study[]> | undefined;
}
