<div class="barra-lateral-dir">
  <div class="box-barra-dir">
    <button mat-mini-fab matTooltip="Prontuário" matTooltipPosition="left"  color="primary" (click)="openProntuario()">
        <mat-icon class="material-symbols-outlined">lab_profile</mat-icon>
    </button>
    <button mat-mini-fab matTooltip="Anexar Imagem" matTooltipPosition="left" matBadge="12" matBadgePosition="after" matBadgeColor="warn" color="primary">
        <mat-icon class="material-symbols-outlined">attachment</mat-icon>
    </button>
    <button mat-mini-fab matTooltip="Comentários" matTooltipPosition="left" matBadge="7" matBadgePosition="after" matBadgeColor="warn" color="primary" (click)="openComentarios()">
        <mat-icon class="material-symbols-outlined">3p</mat-icon>
    </button>
  </div>
</div>
<div class="mdc-layout-grid__inner laudo">
  <div class="mdc-layout-grid__cell--span-3">
    <div class="head-laudo">
      <table mat-table class="w-100">
        <tr>
          <td class="divisoria-hor w-100" colspan="2">
            <h2>Área de Laudo</h2>
          </td>
        </tr>
        <tr>
          <td class="divisoria-hor divisoria-ver w-70">
            <label>Paciente</label>
            <div class="body2">{{study?.patient?.name}}</div>
            <div class="caption"> --idade anos e meses -- | {{study?.patient?.gender}}</div>
          </td>
          <td class="divisoria-hor w-30">
            <div class="body0">{{study?.modality}}</div>
            <div class="caption">{{study?.description}}</div>
          </td>
        </tr>
      </table>
      <table mat-table class="w-100">
        <tr>
          <td class="divisoria-hor" colspan="2">
            <label>Instituição</label>
            <div class="body2"><a routerLink=".">{{study?.institution?.name}}</a></div>
          </td>
        </tr>
        <tr>
          <td class="w-50 divisoria-ver">
            <label>Data/Hora Exame</label>
            <div class="body2">{{study?.studyDate}}</div>
          </td>
          <td class="w-50" color="primary">
            <label>Prazo do Exame</label>
            <div class="body2 status-prazo-ok" >--prazo--</div>
          </td>
        </tr>
      </table>
    </div>
    <div class="comandos-laudo">
      <h4>Máscara de Laudo</h4>
      <div class="mdc-layout-grid__cell">
        <mat-form-field class="input-text-name-filter mat-form-field-flex">
          <mat-label>Pesquisar máscara</mat-label>
          <input type="text" matInput [matAutocomplete]="auto" [formControl]="searchInput" />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTemplate">
            @for (item of filteredOptions | async; track item) {
              <mat-option [value]="item">{{item.description}}</mat-option>
            }
          </mat-autocomplete>
          <button matSuffix mat-icon-button aria-label="Clear" >
            <mat-icon class="material-symbols-outlined">search</mat-icon>
          </button>
        </mat-form-field>
        <mat-radio-group aria-label="Select an option">
          <mat-radio-button value="1" class="body2" color="primary">Usuário</mat-radio-button>
          <mat-radio-button value="2" class="body2" color="primary">Unidade</mat-radio-button>
        </mat-radio-group>
      </div>
      <hr>
      <div class="mdc-layout-grid__cell">
        <h4>Visualizadores</h4>
        <button mat-fab extended color="accent" class="md-small">WEB</button>
        <button mat-fab extended color="accent" class="md-small">Osirix</button>
        <button mat-fab extended color="accent" class="md-small">Radiant</button>
        <button mat-fab extended color="accent" class="md-small">.Zip</button>
      </div>
      <br/>
      <hr>
      <div class="mdc-layout-grid__cell">
        <h4>Exames Anteriores
          <button mat-mini-fab mat-mini-fab matTooltip="Pesquisar Exames"
            matTooltipPosition="above" color="primary" style="float: right"
            (click)="openExamesAnteriores()">
            <mat-icon class="material-symbols-outlined">Search</mat-icon>
          </button>
        </h4>
        <h5>Associados</h5>
        <table mat-table class="tabela-resultados w-100">
          <thead>
            <th>M</th>
            <th>Nome</th>
            <th>Estudo</th>
            <th>Data Exame</th>
          </thead>
          <tr>
            <td>MR</td>
            <td>Joaquim Silveira</td>
            <td>Estudo</td>
            <td>12/02/2024</td>
          </tr>
        </table>
        <h5>Encontrados</h5>
        <table mat-table class="tabela-resultados w-100">
          <thead>
            <th>M</th>
            <th>Nome</th>
            <th>Estudo</th>
            <th>Data Exame</th>
          </thead>
          <tr>
            <td>MR</td>
            <td>Joaquim Silveira</td>
            <td>Estudo</td>
            <td>12/02/2024</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="mdc-layout-grid__cell--span-9">
    <editor
      [init]="editorSettings"
    ></editor>
      <div class="barra-botao-acao-1">
        <mat-slide-toggle color="accent" hideIcon>Achado crítico!</mat-slide-toggle>&nbsp;&nbsp;&nbsp;
        <mat-slide-toggle color="accent" hideIcon>Salvar como máscara</mat-slide-toggle>&nbsp;&nbsp;&nbsp;
        <button mat-fab extended color="primary" class="md-medium" (click)="saveStudy()" [disabled]="btnSalvarDisabled">
          Salvar
          <mat-icon class="material-symbols-outlined">check</mat-icon>
        </button>
        <button mat-fab extended color="primary" class="md-medium">
          Pré-visualizar
          <mat-icon class="material-symbols-outlined">frame_inspect</mat-icon>
        </button>
        <button mat-fab extended color="primary" class="md-medium">
          Assinar
          <mat-icon class="material-symbols-outlined">developer_guide</mat-icon>
        </button>
      </div>
  </div>
