import {Component, ElementRef, ChangeDetectorRef, isDevMode, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { KeycloakService } from "keycloak-angular";
import { KeycloakProfile } from "keycloak-js";
import { RouterLink } from '@angular/router';
import { RouterLinkActive } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { Erro404Component } from './pages/erros/erro-404/erro-404.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    DashboardComponent,
    Erro404Component
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent implements OnInit {
  title = "Intevo Health Care";
  user: KeycloakProfile | undefined

  constructor(private cdRef:ChangeDetectorRef, private kc: KeycloakService) {
    kc.loadUserProfile().then(user => {
      this.user =  user ;
      console.log("########## firstName " + this.user.firstName)
    })
    kc.getToken().then( token => {
      console.log("########## getToken " + token)
    })
  }

  ngOnInit() {
    if (isDevMode()) {
      console.log('Development!');
    } else {
      console.log('Production!');
    }
  }

  async logout() {
    await this.kc.logout()
  }
}
