import { HttpClient, HttpParams } from "@angular/common/http";
import {environment} from "../../environments/environment";
import { Pageable } from "../model/pageable";
import { Customer } from "../model/customer";
import { BaseService } from "./base.service";
import { Injectable } from "@angular/core";
import { Page } from "../model/page";
import { Observable } from "rxjs";

//import {HttpCustomer, HttpParams} from "@angular/common/http";

interface customerSearchFilter {
  name: string;
}

@Injectable({
  providedIn: "root",
})
export class CustomerService extends BaseService<Customer> {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  search(filter: customerSearchFilter, page: Pageable): Observable<Page<Customer>> {
    let params = new HttpParams()
      .set("offset", page.offset | 1)
      .set("limit", page.limit)
      .set("name", filter.name);

    return this.get(`${environment.iam}/iam/customers`, params);
  }

  create(customer: Customer): Observable<Customer> {
    return this.post(`${environment.iam}/iam/customers`, customer);
  }

  remove(id: string): Observable<Customer> {
    return this.delete(`${environment.iam}/iam/customers/${id}`);
  }

  load(id: string): Observable<Customer>{
    return this.get(`${environment.iam}/iam/customers/${id}`);
  }
  update(customer: Customer): Observable<Customer>{
    return this.put(`${environment.iam}/iam/customers/${customer.id}`, customer);
  }
}
