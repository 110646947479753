import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { LogoutComponent } from "./pages/logout/logout.component";
import { Erro404Component } from "./pages/erros/erro-404/erro-404.component";
import { Erro500Component } from "./pages/erros/erro-500/erro-500.component";
import {
  authorizationChildrenGuard,
  authorizationGuard,
} from "./authorization/authorization.guard";

import { CustomerSearchComponent } from "./pages/admin/customer/customer-search/customer-search.component";
import { CustomerCreateComponent } from "./pages/admin/customer/customer-create/customer-create.component";
import { CustomerEditComponent } from "./pages/admin/customer/customer-edit/customer-edit.component";

import { UserSearchComponent } from "./pages/admin/user/user-search/user-search.component";
import { UserCreateComponent } from "./pages/admin/user/user-create/user-create.component";
import { UserEditComponent } from "./pages/admin/user/user-edit/user-edit.component";
import { ExamEditComponent } from "./pages/exam/exam-edit/exam-edit.component";

export const appRoutes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    canActivate: [authorizationGuard],
    canActivateChild: [authorizationChildrenGuard],
  },
  { path: "logout", component: LogoutComponent },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [authorizationGuard],
    canActivateChild: [authorizationChildrenGuard],
  },
  {
    path: "tab/laudo/:cid/:idlaudo",
    component: DashboardComponent,
    canActivate: [authorizationGuard],
    canActivateChild: [authorizationChildrenGuard],
  },
  {
    path: "admin/customer",
    component: CustomerSearchComponent,
    canActivate: [authorizationGuard],
    canActivateChild: [authorizationChildrenGuard],
    //data: {roles:['ADMINISTRADOR']},
  },
  {
    path: "admin/customer/new",
    component: CustomerCreateComponent,
    canActivate: [authorizationGuard],
    canActivateChild: [authorizationChildrenGuard],
  },
  {
    path: "admin/customer/:id/edit",
    component: CustomerEditComponent,
    canActivate: [authorizationGuard],
    canActivateChild: [authorizationChildrenGuard],
  },
  {
    path: "admin/user",
    component: UserSearchComponent,
    canActivate: [authorizationGuard],
    canActivateChild: [authorizationChildrenGuard],
  },
  {
    path: "admin/user/new",
    component: UserCreateComponent,
    canActivate: [authorizationGuard],
    canActivateChild: [authorizationChildrenGuard],
  },
  {
    path: "admin/user/:id/edit",
    component: UserEditComponent,
    canActivate: [authorizationGuard],
    canActivateChild: [authorizationChildrenGuard],
  },
  {
    path: "exam/exam-edit",
    component: ExamEditComponent,
    canActivate: [authorizationGuard],
    canActivateChild: [authorizationChildrenGuard],
  },
  { path: "**", component: Erro404Component },
  { path: "500", component: Erro500Component },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
