import { Component, OnInit, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardMenuComponent } from "../../dashboard/dashboard-menu/dashboard-menu.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatOptionModule } from "@angular/material/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../../../shared/modal/confirm-dialog/confirm-dialog.component";
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatMenuModule } from "@angular/material/menu";
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { Router, RouterLink, ActivatedRoute, RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-exam-edit',
  standalone: true,
  imports: [
    CommonModule,
    DashboardMenuComponent,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    ReactiveFormsModule,
    RouterLink,
    RouterOutlet,
    MatProgressBarModule,
    MatMenuModule
  ],
  templateUrl: './exam-edit.component.html',
  styleUrl: './exam-edit.component.scss'
})
export class ExamEditComponent implements OnInit {
  isDisabled: boolean = false;
  form: FormGroup;
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {
    this.form = this.formBuilder.group({
      pacient: [null, Validators.required],
      age: [null, Validators.required],
      document: [null, Validators.required],
      cpf: [null, Validators.required],
      sus: [null, Validators.required],
    })
  }

  ngOnInit() {};
}
