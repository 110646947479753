<div class="menu">
  <app-dashboard-menu></app-dashboard-menu>
</div>
<div class="mdc-layout-grid">
  <div class="mdc-layout-grid__inner">
    <img class="logo" src="/assets/logotipo.png" alt="logo" />
    <div class="mdc-layout-grid__cell--span-12">
      <mat-tab-group animationDuration="0ms">
        <mat-tab label="Exame: Maria Cybele Pereira da Silva">
          <form [formGroup]="form">
            <!--        <div class="barra-lateral-dir"></div>   -->
            <div class="mdc-layout-grid">
            <div class="mdc-layout-grid__inner">
              <div class="mdc-layout-grid__cell--span-7">
                <h3>Dados do Exame</h3>
                <mat-card class="filter-card">
                  <mat-card-content>
                    <div class="mdc-layout-grid">
                      <div class="mdc-layout-grid__inner">
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
                          <mat-form-field
                            class="select-status-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Instituição</mat-label>
                            <mat-select  >
                              <mat-option>Selecione</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
                          <mat-form-field 
                            class="input-text-name-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Data do Exame</mat-label>
                            <input matInput [matDatepicker]="picker" />
                            <mat-datepicker-toggle
                              matIconSuffix
                              [for]="picker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
                          <mat-form-field
                            class="input-text-name-filter mat-form-field-flex w-100"
                          >
                            <mat-label>CID</mat-label>
                            <input class="" matInput type="text" />
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
                          <mat-form-field
                            class="input-text-name-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Médico Solicitante</mat-label>
                            <input class="" matInput type="text" />
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell--span-3">
                          <mat-form-field
                            class="select-status-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Prioridade</mat-label>
                            <mat-select  >
                              <mat-option>Selecione</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell--span-3">
                          <mat-form-field
                            class="select-status-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Status do Exame</mat-label>
                            <mat-select  >
                              <mat-option>Selecione</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell--span-6">
                          <mat-form-field
                            class="select-status-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Tipo do Exame</mat-label>
                            <mat-select  >
                              <mat-option>Selecione</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell--span-6">
                          <mat-form-field
                            class="select-status-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Estudo do Exame</mat-label>
                            <mat-select  >
                              <mat-option>Selecione</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      </div>
                  </mat-card-content>
                </mat-card>
                <br/>
                <h3>Dados do Paciente</h3>
                <mat-card class="filter-card">
                  <mat-card-content>
                    <div class="mdc-layout-grid">
                      <div class="mdc-layout-grid__inner">
                        <div class="mdc-layout-grid__cell--span-6">
                          <mat-form-field
                            class="input-text-name-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Paciente</mat-label>
                            <input
                              class=""
                              matInput
                              type="text"
                              formControlName="pacient"
                            />
                            @if (form.controls['pacient']) {
                              <button
                                matSuffix
                                mat-icon-button
                                aria-label="Clear"
                                (click)="form.controls['pacient'].setValue('')"
                              >
                                <mat-icon class="material-symbols-outlined"
                                  >cancel</mat-icon
                                >
                              </button>
                              }
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell--span-3">
                          <mat-form-field
                            class="input-text-name-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Data de Nasc.</mat-label>
                            <input matInput [matDatepicker]="picker" />
                            <mat-datepicker-toggle
                              matIconSuffix
                              [for]="picker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell--span-3">
                          <mat-form-field
                            class="input-text-name-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Idade</mat-label>
                            <input class="" matInput type="text" disabled="true"/>
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell--span-3">
                          <mat-form-field
                            class="select-status-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Gênero</mat-label>
                            <mat-select  >
                              <mat-option>Selecione</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell--span-3">
                          <mat-form-field
                            class="select-status-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Estado</mat-label>
                            <mat-select  >
                              <mat-option>Selecione</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell--span-6">
                          <mat-form-field
                            class="select-status-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Cidade</mat-label>
                            <mat-select  >
                              <mat-option>Selecione</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell--span-3">
                          <mat-form-field
                            class="input-text-name-filter mat-form-field-flex w-100"
                          >
                            <mat-label>RG</mat-label>
                            <input
                              class=""
                              matInput
                              type="text"
                              formControlName="document"
                            />
                            @if (form.controls['document']) {
                              <button
                                matSuffix
                                mat-icon-button
                                aria-label="Clear"
                                (click)="form.controls['document'].setValue('')"
                              >
                                <mat-icon class="material-symbols-outlined"
                                  >cancel</mat-icon
                                >
                              </button>
                              }
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell--span-3">
                          <mat-form-field
                            class="input-text-name-filter mat-form-field-flex w-100"
                          >
                            <mat-label>CPF</mat-label>
                            <input
                              class=""
                              matInput
                              type="text"
                              formControlName="cpf"
                            />
                            @if (form.controls['cpf']) {
                              <button
                                matSuffix
                                mat-icon-button
                                aria-label="Clear"
                                (click)="form.controls['cpf'].setValue('')"
                              >
                                <mat-icon class="material-symbols-outlined"
                                  >cancel</mat-icon
                                >
                              </button>
                              }
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell--span-3">
                          <mat-form-field
                            class="input-text-name-filter mat-form-field-flex w-100"
                          >
                            <mat-label>SUS</mat-label>
                            <input
                              class=""
                              matInput
                              type="text"
                              formControlName="sus"
                            />
                            @if (form.controls['sus']) {
                              <button
                                matSuffix
                                mat-icon-button
                                aria-label="Clear"
                                (click)="form.controls['sus'].setValue('')"
                              >
                                <mat-icon class="material-symbols-outlined"
                                  >cancel</mat-icon
                                >
                              </button>
                              }
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="mdc-layout-grid__cell--span-5">
                <h3>Upload Prontuário Médico</h3>
                <div class="mdc-layout-grid">
                  <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell--span-12">
                      <div class="dropzone-table">
                        <input
                          type="file"
                          #fileDropRefLogo
                          id="fileDropRefLogo"
                        />
                        <p>
                            <mat-icon class="material-symbols-outlined"
                            >upload_file</mat-icon
                            >
                            Arraste seu LOGOTIPO para cá ou
                            <label for="fileDropRefLogo">clique para upload</label>
                        </p>
                     

                        <table mat-table class="tabela-resultados w-100">
                            <thead>
                            <th>Nome do Arquivo</th>
                            <th>Usuário Responsável</th>
                            <th></th>
                            <th></th>
                            </thead>
                            <tr>
                            <td>
                                prontuário_XYZ.pdf
                            </td>
                            <td>Matias Pereira de Arruda</td>
                            <td>1.8MB</td>
                            <td>
                                <button
                                mat-mini-fab
                                matTooltip="Excluir"
                                matTooltipPosition="top"
                                color="primary"
                                >
                                <mat-icon class="material-symbols-outlined"
                                    >delete</mat-icon
                                >
                                </button>
                            </td>
                            </tr>
                            <tr>
                            <td>Prontuário_XYZ_Hospital-Aliança.pdf</td>
                            <td>Aislan Rodrigues de Alencar</td>
                            <td>
                                <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
                            </td>
                            <td>
                                <button
                                mat-mini-fab
                                matTooltip="Excluir"
                                matTooltipPosition="top"
                                color="primary"
                                >
                                <mat-icon class="material-symbols-outlined"
                                    >delete</mat-icon
                                >
                                </button>
                            </td>
                            </tr>
                        </table>                      
                        </div>
                    </div>
                    <div class="mdc-layout-grid__cell--span-12">
                      <h3>Comentários do Exame</h3>
                      <table mat-table class="tabela-resultados w-100">
                        <thead>
                          <th>Comentário</th>
                          <th>Usuário Responsável</th>
                          <th>Data/Hora Criação</th>
                          <th></th>
                        </thead>
                        <tr>
                          <td>
                            Repetir perfil com todas as vertebras aparecendo.
                            inclusive sua parte posterior
                          </td>
                          <td>Matias Pereira de Arruda</td>
                          <td>20/03/2024 | 03:00</td>
                          <td>
                            <button
                              mat-mini-fab
                              matTooltip="Excluir"
                              matTooltipPosition="top"
                              color="primary"
                            >
                              <mat-icon class="material-symbols-outlined"
                                >delete</mat-icon
                              >
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>Paciente sentindo dores</td>
                          <td>Aislan Rodrigues de Alencar</td>
                          <td>20/03/2024 | 22:32</td>
                          <td>
                            <button
                              mat-mini-fab
                              matTooltip="Excluir"
                              matTooltipPosition="top"
                              color="primary"
                            >
                              <mat-icon class="material-symbols-outlined"
                                >delete</mat-icon
                              >
                            </button>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="mdc-layout-grid__cell--span-12">
                      <h3>Histórico</h3>
                      <table mat-table class="tabela-resultados w-100">
                        <thead>
                          <th>Evento</th>
                          <th>Usuário Resp.</th>
                          <th>Status Anterior</th>
                          <th>Status Resultado</th>
                          <th>Data Evento</th>
                        </thead>
                        <tr>
                          <td>Criação do Exame</td>
                          <td>Matias Pereira de Arruda</td>
                          <td></td>
                          <td>disponível para laudo</td>
                          <td>20/03/2024 | 03:00</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mdc-layout-grid__cell--span-12">
                <div class="barra-botao-acao-1">
                  <button
                    mat-fab
                    extended
                    color="surface"
                    class="md-medium"
                    [routerLink]="['/dashboard']"
                  >
                    Voltar
                    <mat-icon class="material-symbols-outlined">reply</mat-icon>
                  </button>
                  <button mat-fab extended color="primary" class="md-medium" [matMenuTriggerFor]="menu">
                    Mais Ações
                    <mat-icon class="material-symbols-outlined">more_horiz</mat-icon>
                  </button>
                    <mat-menu #menu="matMenu">
                    <button mat-menu-item disabled="true">Desbloquear</button>
                    <button mat-menu-item disabled="true">Bloquear</button>
                    <button mat-menu-item disabled="true">Comentar</button>
                    <button mat-menu-item disabled="true">Laudar</button>
                    <button mat-menu-item disabled="true">WEB</button>
                    <button mat-menu-item disabled="true">Weasis</button>
                    <button mat-menu-item disabled="true">ISO</button>
                    <button mat-menu-item disabled="true">.Zip</button>
                    </mat-menu>
                  <button mat-fab extended color="primary" class="md-medium">
                    Novo
                    <mat-icon class="material-symbols-outlined">add</mat-icon>
                  </button>
                  <button mat-fab extended color="primary" class="md-medium">
                    Salvar
                    <mat-icon class="material-symbols-outlined">check</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          </form>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
