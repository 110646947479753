import { Component, OnInit, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardMenuComponent } from "../../../dashboard/dashboard-menu/dashboard-menu.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatOptionModule } from "@angular/material/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDialog } from "@angular/material/dialog";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ConfirmDialogComponent } from "../../../../shared/modal/confirm-dialog/confirm-dialog.component";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { AlertService } from "../../../../services/alert.service";
import { UserService } from "../../../../services/user.service";
import { CustomerService } from "../../../../services/customer.service";
import { User } from "../../../../model/user";
import { Customer } from "../../../../model/customer";
import { CustomerRelated } from "../../../../model/customer_related";
import { Router, RouterLink, ActivatedRoute } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Pageable } from "../../../../model/pageable";
import { Page } from "../../../../model/page";

interface customerSearchFilter {
  name: string;
}

@Component({
  selector: "app-user-edit",
  standalone: true,
  imports: [
    CommonModule,
    DashboardMenuComponent,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    ReactiveFormsModule,
    RouterLink,
    MatAutocompleteModule,
  ],
  templateUrl: "./user-edit.component.html",
  styleUrl: "./user-edit.component.scss",
})
export class UserEditComponent implements OnInit {
  isDisabled: boolean = false;
  form: FormGroup;

  userService = inject(UserService);
  alertService = inject(AlertService);
  customerService = inject(CustomerService);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);

  user$: Observable<User> = of();
  userEdit: User | undefined;

  fieldSearch = new FormGroup({
    name: new FormControl<string>(""),
  });

  listCustomers$: Observable<Customer[]> = of();
  page: Page<Customer> | undefined;
  selectedCustomer: Customer | undefined;

  //customers related
  customers$: Observable<CustomerRelated[]> = of();
  pageReleted: Page<CustomerRelated> | undefined;

  listRoles = [
    "ADMINISTRADOR",
    "ATENDENTE",
    "COORDENADOR_MEDICO",
    "COORDENADOR_TECNICO",
    "USER",
  ];
  formRoles = new FormGroup({
    role: new FormControl<string>(""),
  });

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {
    this.form = this.formBuilder.group({
      id: [null, Validators.required],
      name: [null, Validators.required],
      surname: [null, Validators.required],
      email: [null, Validators.required],
      identity: [null],
      roles: [null],
    });
  }

  ngOnInit() {
    const userId = this.activatedRoute.snapshot.paramMap.get("id");
    this.userService.load(userId!).subscribe({
      next: (user: User) => {
        this.user$ = of(user);
        this.userEdit = user;
        this.form.patchValue(user);
        //load customers
        this.loadUserCustomer();
      },
      error: (e) => {
        console.log(e);
      },
    });
    //search customer
    this.fieldSearch.valueChanges.subscribe((searchFilter) => {
      let pageable: Pageable = {
        sort: "name,desc",
        offset: 1,
        limit: 10,
      };
      this.customerService
        .search(searchFilter as customerSearchFilter, pageable)
        .subscribe({
          next: (page: Page<Customer>) => {
            //this.searchForm.enable();
            this.page = page;
            if (this.page != null) {
              this.listCustomers$ = of(this.page.content);
              console.log(this.page.content);
            } else {
              this.listCustomers$ = of();
            }
          },
          error: (e) => {
            console.error(e);
          },
          complete: () => {
            //console.info("complete");
          },
        });
    });
  }

  onSubmit(): void {
    if (this.form.invalid) {
      console.log("invalido");
      console.log(this.form);
      return;
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: "350px",
        data: { message: "Tem certeza que deseja alterar este usuário?" },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const formData = this.form.value; // Get form data
          this.userService.update(formData).subscribe(
            (editedUser) => {
              this.alertService.openSnackBar("Salvo com sucesso!");
              this.router.navigate(["/admin/user"]);
              console.log(editedUser);
            },
            (error) => {
              this.alertService.openSnackBar(
                "Um erro ocorreu ao tentar salvar."
              );
              console.log("error");
              console.error(error);
            }
          );
        }
      });
    }
  }

  displayFn(customer: Customer): string {
    return "";
  }
  setSelectedCustomer(customer: Customer) {
    this.selectedCustomer = customer;
    this.addSelectedCustomer();
  }
  loadUserCustomer() {
    let pageable: Pageable = {
      sort: "name,desc",
      offset: 1,
      limit: 10,
    };
    this.userService.loadUserCustomers(this.userEdit!, pageable).subscribe({
      next: (page: Page<CustomerRelated>) => {
        if (page != null) {
          console.log(page.content);
          this.customers$ = of(page.content);
        }
      },
      error: (e) => {
        console.log(e);
      },
    });
  }
  addSelectedCustomer() {
    if (this.selectedCustomer == undefined) {
      console.log("invalido");
      return;
    } else {
      let relcustomer = [
        {
          name: this.selectedCustomer?.name,
          id: this.selectedCustomer?.id,
          role: "USER",
        },
      ] as CustomerRelated[];
      this.userService.saveUserCustomers(this.userEdit!, relcustomer).subscribe(
        (customers) => {
          this.alertService.openSnackBar("Salvo com sucesso!");
          this.loadUserCustomer();
          console.log(customers);
        },
        (error) => {
          this.alertService.openSnackBar("Um erro ocorreu ao tentar salvar.");
          console.log("error");
          console.error(error);
        }
      );
    }
  }

  updatePermission(id: string, role: string) {
    if (id == undefined || role == undefined) {
      console.log("invalido");
      return;
    } else {
      let update = { id: id, name: null, role: role };
      this.userService.updateUserCustomers(this.userEdit!, update!).subscribe(
        (customers) => {
          this.alertService.openSnackBar("Salvo com sucesso!");
          console.log(customers);
        },
        (error) => {
          this.alertService.openSnackBar("Um erro ocorreu ao tentar salvar.");
          console.log("error");
          console.error(error);
        }
      );
    }
  }

  deleteSelectedCustomer(customerId: string) {
    if (customerId == undefined) {
      console.log("invalido");
      return;
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: "350px",
        data: { message: "Tem certeza que deseja excluir esta relação?" },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.userService
            .removeUserCustomers(this.userEdit!, customerId)
            .subscribe(
              () => {
                this.alertService.openSnackBar("Exluído com sucesso!");
                this.loadUserCustomer();
              },
              (error) => {
                this.alertService.openSnackBar(
                  "Um erro ocorreu ao tentar excluir."
                );
              }
            );
        }
      });
    }
  }
}
