import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { StudyFilter } from "../model/study_filter";
import { Pageable } from "../model/pageable";
import { Injectable, inject } from "@angular/core";
import { BaseService } from "./base.service";
import { Study } from "../model/study";
import { Page } from "../model/page";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StudyService extends BaseService<Study> {

  constructor(protected override http: HttpClient) {
    super(http);
  }

  searchStudies(study: StudyFilter, page: Pageable): Observable<Page<Study>> {
    let params = new HttpParams()
      .set("offset", page.offset | 1)
      .set("limit", page.limit)
      .set("InstitutionId", study.institution);

    if (study.start != null) {
      params = params.set("StudyDateStart", study.start.toISOString());
    }

    if (study.end != null) {
      params = params.set("StudyDateEnd", study.end.toISOString());
    }

    if (study.status_study != null && study.status_study != "") {
      params = params.set("StudyStatus", study.status_study);
    }
    if (study.pacient != null && study.pacient != "") {
      params = params.set("PatientName", study.pacient);
    }

    if (study.executor_doctor != null && study.executor_doctor != "") {
      params = params.set("PhysicianName", study.executor_doctor);
    }
    const severity = {
      emergency: "EMERGING",
      urgency: "URGENT",
      routine: "NON_URGENT",
      hospitalized: "LEAST_URGENT",
    };
    const keys = Object.keys(severity) as Array<keyof typeof study>;
    let keyServerity: string[] = [];
    keys.forEach((key) => {
      if (study[key] != null && study[key] != "" && study[key]) {
        keyServerity.push(severity[key as keyof typeof severity]);
      }
    });
    if (keyServerity.length > 0) {
      params = params.set("StudySeverity", keyServerity.join(","));
    }

    return this.get(`${environment.dcm}/his/studies`, params);
  }

  loadStudyById(id: string, cid: string): Observable<Study> {
    return this.get(`${environment.dcm}/his/${cid}/studies/${id}`);
  }
}
