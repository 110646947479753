<div class="menu">
  <app-dashboard-menu></app-dashboard-menu>
</div>
<div class="mdc-layout-grid">
  <div class="mdc-layout-grid__inner">
    <img class="logo" src="/assets/logotipo.png" alt="logo" />
    <div class="mdc-layout-grid__cell--span-12">
      <mat-tab-group animationDuration="0ms">
        <mat-tab label="Nova Instituição">
          <form [formGroup]="form">
            <!--        <div class="barra-lateral-dir"></div>   -->
            <div class="mdc-layout-grid__inner">
              <div class="mdc-layout-grid__cell--span-12">
                <mat-card class="filter-card">
                  <mat-card-content>
                    <div class="mdc-layout-grid">
                      <div class="mdc-layout-grid__inner">
                        <div class="mdc-layout-grid__cell--span-3">
                          <h3>Informações</h3>
                          <div class="mdc-layout-grid__cell--span-12">
                            <mat-form-field
                              class="input-text-name-filter mat-form-field-flex w-100"
                            >
                              <mat-label>Instituição</mat-label>
                              <input
                                class=""
                                matInput
                                type="text"
                                formControlName="name"
                              />
                            </mat-form-field>
                          </div>
                          <div class="mdc-layout-grid__cell--span-12">
                            <mat-form-field
                              class="input-text-name-filter mat-form-field-flex w-100"
                            >
                              <mat-label>Descrição</mat-label>
                              <input
                                class=""
                                matInput
                                type="text"
                                formControlName="description"
                              />
                            </mat-form-field>
                          </div>
                          <div class="mdc-layout-grid__cell--span-12">
                            <mat-form-field
                              class="input-text-name-filter mat-form-field-flex w-100"
                            >
                              <mat-label>CNPJ</mat-label>
                              <input
                                class=""
                                matInput
                                type="text"
                                formControlName="document"
                              />
                            </mat-form-field>
                          </div>
                          <div class="mdc-layout-grid__cell--span-12">
                            <mat-form-field
                              class="select-status-filter mat-form-field-flex w-100"
                            >
                              <mat-label>Grupo Pertencente</mat-label>
                              <mat-select formControlName="roles">
                                <mat-option>Selecione</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div
                            class="mdc-layout-grid__cell--span-12"
                            formGroupName="address"
                          >
                            <mat-form-field
                              class="input-text-name-filter mat-form-field-flex w-100"
                            >
                              <mat-label>Endereço</mat-label>
                              <input
                                class=""
                                matInput
                                type="text"
                                formControlName="street"
                              />
                            </mat-form-field>
                          </div>
                          <div
                            class="mdc-layout-grid__cell--span-12"
                            formGroupName="address"
                          >
                            <mat-form-field
                              class="select-status-filter mat-form-field-flex w-100"
                            >
                              <mat-label>Estado</mat-label>
                              <mat-select formControlName="state">
                                <mat-option>Selecione</mat-option>
                                <mat-option value="BA">Bahia</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div
                            class="mdc-layout-grid__cell--span-12"
                            formGroupName="address"
                          >
                            <mat-form-field
                              class="select-status-filter mat-form-field-flex w-100"
                            >
                              <mat-label>Cidade</mat-label>
                              <mat-select formControlName="city">
                                <mat-option>Selecione</mat-option>
                                <mat-option value="salvador"
                                  >Salvador</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div
                            class="mdc-layout-grid__cell--span-12"
                            formGroupName="address"
                          >
                            <mat-form-field
                              class="input-text-name-filter mat-form-field-flex w-100"
                            >
                              <mat-label>CEP</mat-label>
                              <input
                                class=""
                                matInput
                                type="text"
                                formControlName="zipCode"
                              />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="mdc-layout-grid__cell--span-3">
                          <h3>Contatos</h3>
                          <div
                            class="mdc-layout-grid__cell--span-12"
                            formGroupName="owner"
                          >
                            <mat-form-field
                              class="input-text-name-filter mat-form-field-flex w-100"
                            >
                              <mat-label>Contato</mat-label>
                              <input
                                class=""
                                matInput
                                type="text"
                                formControlName="name"
                              />
                            </mat-form-field>
                          </div>
                          <div
                            class="mdc-layout-grid__cell--span-12"
                            formGroupName="owner"
                          >
                            <mat-form-field
                              class="input-text-name-filter mat-form-field-flex w-100"
                            >
                              <mat-label>e-Mail Contato</mat-label>
                              <input
                                class=""
                                matInput
                                type="email"
                                formControlName="email"
                              />
                            </mat-form-field>
                          </div>
                          <div class="mdc-layout-grid__cell--span-12">
                            <div
                              class="mdc-layout-grid__cell--span-12"
                              formGroupName="owner"
                            >
                              <mat-form-field
                                class="input-text-name-filter mat-form-field-flex w-100"
                              >
                                <mat-label>Telefone Contato</mat-label>
                                <input
                                  class=""
                                  matInput
                                  type="tel"
                                  formControlName="telephone"
                                />
                              </mat-form-field>
                            </div>
                            <div
                              class="mdc-layout-grid__cell--span-12"
                              formGroupName="manager"
                            >
                              <mat-form-field
                                class="input-text-name-filter mat-form-field-flex w-100"
                              >
                                <mat-label>Resp. Técnico</mat-label>
                                <input
                                  class=""
                                  matInput
                                  type="text"
                                  formControlName="name"
                                />
                              </mat-form-field>
                            </div>
                            <div
                              class="mdc-layout-grid__cell--span-12"
                              formGroupName="manager"
                            >
                              <mat-form-field
                                class="input-text-name-filter mat-form-field-flex w-100"
                              >
                                <mat-label>e-Mail Resp. Técnico</mat-label>
                                <input
                                  class=""
                                  matInput
                                  type="email"
                                  formControlName="email"
                                />
                              </mat-form-field>
                            </div>
                            <div
                              class="mdc-layout-grid__cell--span-12"
                              formGroupName="manager"
                            >
                              <mat-form-field
                                class="input-text-name-filter mat-form-field-flex w-100"
                              >
                                <mat-label>CRM</mat-label>
                                <input
                                  class=""
                                  matInput
                                  type="text"
                                  formControlName="document"
                                />
                              </mat-form-field>
                            </div>
                            <div
                              class="mdc-layout-grid__cell--span-12"
                              formGroupName="manager"
                            >
                              <mat-form-field
                                class="input-text-name-filter mat-form-field-flex w-100"
                              >
                                <mat-label>Telefone Resp. Técnico</mat-label>
                                <input
                                  class=""
                                  matInput
                                  type="phone"
                                  formControlName="telephone"
                                />
                              </mat-form-field>
                            </div>
                            <div class="mdc-layout-grid__cell--span-12">
                              <mat-form-field
                                class="input-text-name-filter mat-form-field-flex w-100"
                              >
                                <mat-label>LifecyclePolicy</mat-label>
                                <input
                                  class=""
                                  matInput
                                  type="number"
                                  formControlName="bucketLifecyclePolicy"
                                />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                        <div class="mdc-layout-grid__cell--span-6">
                          <h3>Configurações SLA</h3>
                          <div class="mdc-layout-grid">
                            <div class="mdc-layout-grid__inner">
                              <div
                                class="mdc-layout-grid__cell--span-3"
                                formGroupName="sla"
                              >
                                <mat-form-field
                                  class="input-text-name-filter mat-form-field-flex w-100 emergencia"
                                >
                                  <mat-label>Emergêncial</mat-label>
                                  <input
                                    class=""
                                    matInput
                                    type="number"
                                    formControlName="emergency"
                                  />
                                </mat-form-field>
                              </div>
                              <div
                                class="mdc-layout-grid__cell--span-3"
                                formGroupName="sla"
                              >
                                <mat-form-field
                                  class="input-text-name-filter mat-form-field-flex w-100 urgencia"
                                >
                                  <mat-label>Urgência</mat-label>
                                  <input
                                    class=""
                                    matInput
                                    type="number"
                                    formControlName="urgency"
                                  />
                                </mat-form-field>
                              </div>
                              <div
                                class="mdc-layout-grid__cell--span-3"
                                formGroupName="sla"
                              >
                                <mat-form-field
                                  class="input-text-name-filter mat-form-field-flex w-100 rotina"
                                >
                                  <mat-label>Rotina</mat-label>
                                  <input
                                    class=""
                                    matInput
                                    type="number"
                                    formControlName="routine"
                                  />
                                </mat-form-field>
                              </div>
                              <div
                                class="mdc-layout-grid__cell--span-3"
                                formGroupName="sla"
                              >
                                <mat-form-field
                                  class="input-text-name-filter mat-form-field-flex w-100 hospitalizado"
                                >
                                  <mat-label>Hospitalizado</mat-label>
                                  <input
                                    class=""
                                    matInput
                                    type="number"
                                    formControlName="hospitalized"
                                  />
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                          <br />
                          <h3>Anexar Arquivos</h3>
                          <div class="mdc-layout-grid">
                            <div class="mdc-layout-grid__inner">
                              <div class="mdc-layout-grid__cell--span-12">
                                <div class="dropzone">
                                  <input
                                    type="file"
                                    #fileDropRefLogo
                                    id="fileDropRefLogo"
                                  />
                                  <p>
                                    <mat-icon class="material-symbols-outlined"
                                      >upload_file</mat-icon
                                    >
                                    Arraste seu LOGOTIPO para cá ou
                                    <label for="fileDropRefLogo"
                                      >clique para upload</label
                                    >
                                  </p>
                                </div>
                              </div>
                              <div class="mdc-layout-grid__cell--span-12">
                                <div class="dropzone">
                                  <input
                                    type="file"
                                    #fileDropRefTemplate
                                    id="fileDropRefTemplate"
                                  />
                                  <p>
                                    <mat-icon class="material-symbols-outlined"
                                      >upload_file</mat-icon
                                    >
                                    Arraste seu TEMPLATE para cá ou
                                    <label for="fileDropRefTemplate"
                                      >clique para upload</label
                                    >
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
                <div class="barra-botao-acao-1">
                  <button
                    mat-fab
                    extended
                    color="surface"
                    class="md-medium"
                    [routerLink]="['../']"
                  >
                    Voltar
                    <mat-icon class="material-symbols-outlined">reply</mat-icon>
                  </button>
                  <button
                    mat-fab
                    extended
                    color="primary"
                    class="md-medium"
                    disabled="true"
                  >
                    Excluir
                    <mat-icon class="material-symbols-outlined"
                      >delete</mat-icon
                    >
                  </button>
                  <button
                    mat-fab
                    extended
                    color="primary"
                    class="md-medium"
                    (click)="onSubmit()"
                  >
                    Salvar
                    <mat-icon class="material-symbols-outlined">check</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
