<router-outlet></router-outlet>
<div class="mdc-layout-grid">
    <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell--span-12 erro-sistema">
            <img src="/assets/erro-500.jpg"/>
            <h1>Erro Interno de Servidor</h1>
            <p>Fique tranquilo, estamos cientes e trabalhando na correção.</p>
            <button mat-fab extended [routerLink]="['/']">
                Voltar ao início
            </button>
        </div>
    </div>
</div>
