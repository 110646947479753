<h2>Comentários</h2>
<mat-dialog-content class="mat-typography">
  <table mat-table class="tabela-modal w-100">
    <thead>
      <th>Comentário</th>
      <th>Data da Criação</th>
      <th></th>
    </thead>
    <tr>
      <td class="w-50">Repetir perfil com todas as vertebras aparecendo. inclusive sua parte posterior</td>
      <td>20/03/2024 | 03:00</td>
      <td>
        <button mat-mini-fab mat-mini-fab matTooltip="Excluir" matTooltipPosition="above" color="primary">
          <mat-icon class="material-symbols-outlined">delete</mat-icon>
      </button>
      </td>
    </tr>
    <tr>
      <td class="w-50">Paciente sentindo dores</td>
      <td>20/03/2024 | 03:50</td>
      <td>
        <button mat-mini-fab mat-mini-fab matTooltip="Excluir" matTooltipPosition="above" color="primary">
          <mat-icon class="material-symbols-outlined">delete</mat-icon>
      </button>
      </td>
    </tr>
  </table>
  <h4>*Destinado ao histórico clínico</h4>
  <div class="mdc-layout-grid__inner">
    <div class="mdc-layout-grid__cell--span-11 barra-botao-acao-1">

    </div>
    <div class="mdc-layout-grid__cell--span-1">
      <button mat-mini-fab mat-mini-fab matTooltip="Enviar comentário" matTooltipPosition="above" color="primary">
        <mat-icon class="material-symbols-outlined">Send</mat-icon>
      </button>
    </div>
  </div> 

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-fab extended color="surface" [mat-dialog-close]="true" cdkFocusInitial class="md-medium">
    Fechar
    <mat-icon class="material-symbols-outlined">close</mat-icon>
  </button>
  <button mat-fab extended color="accent" [mat-dialog-close]="true" cdkFocusInitial class="md-medium">
    Pendente
    <mat-icon class="material-symbols-outlined">work_history</mat-icon>
  </button>
  <button mat-fab extended color="accent" [mat-dialog-close]="true" cdkFocusInitial class="md-medium">
    Reconvocar
    <mat-icon class="material-symbols-outlined">campaign</mat-icon>
  </button>
  <button mat-fab extended color="accent" [mat-dialog-close]="true" cdkFocusInitial class="md-medium">
    Salvar
    <mat-icon class="material-symbols-outlined">check</mat-icon>
  </button>
</mat-dialog-actions>