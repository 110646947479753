import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {inject} from "@angular/core";
import {KeycloakService} from "keycloak-angular";

export const authorizationGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router: Router = inject(Router);
  const keycloak: KeycloakService = inject(KeycloakService);
  const isLogged: boolean = await keycloak.isLoggedIn();

  if (!isLogged) {
    await keycloak.login({
      redirectUri: window.location.origin + state.url,
    }).catch((e) => console.error(e));
    return false;
  }

  // Get the roles required from the route.
  const requiredRoles: string[] = route.data["roles"];

  // Allow the user to proceed if no additional roles are required to access the route.
  if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
    return true;
  }
  // Allow the user to proceed if all the required roles are present.
  
  let userRoles = keycloak.getUserRoles(true);
  //const validateRoles = requiredRoles.every((role) => keycloak.getUserRoles(true).includes(role));
  const validateRoles = requiredRoles.every((role) => userRoles.includes(role));
  if(validateRoles){
    return true;
  } else{
    router.navigate(['/noperm']);
    return false;
  }
};


export const authorizationChildrenGuard: CanActivateFn = async (route, state) => {
  const keycloak: KeycloakService = inject(KeycloakService);

  // Allow the user to proceed if all the required roles are present.
  return await keycloak.isLoggedIn();
};

