<h2>Confirmação</h2>
<mat-dialog-content class="mat-typography modal">
  <p>{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-fab extended color="surface" class="md-medium" [mat-dialog-close]="true">Cancelar
    <mat-icon class="material-symbols-outlined">close</mat-icon>
  </button>
  <button mat-fab extended color="primary" class="md-medium" [mat-dialog-close]="true" cdkFocusInitial>Confirmar
    <mat-icon class="material-symbols-outlined">check</mat-icon>
  </button>
</mat-dialog-actions>
