import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringToBlobService {

  stringToBlob(str: string): Blob {
    // Convert string to a byte array
    const byteArray = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
      byteArray[i] = str.charCodeAt(i);
    }

    // Create a Blob from the byte array
    return new Blob([byteArray], { type: 'application/octet-stream' });
  }
}
