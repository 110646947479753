import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { RouterOutlet } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkWithHref } from '@angular/router';

@Component({
  selector: 'app-erro-404',
  standalone: true,
  imports: [
    MatButtonModule,
    CommonModule,
    HttpClientModule,
    RouterOutlet,
    MatIconModule,
    RouterLinkWithHref,
    RouterLink
  ],
  templateUrl: './erro-404.component.html',
  styleUrl: './erro-404.component.scss'
})
export class Erro404Component {

}
