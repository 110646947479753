<div class="menu">
  <app-dashboard-menu></app-dashboard-menu>
</div>
<div class="mdc-layout-grid">
  <div class="mdc-layout-grid__inner">
    <img class="logo" src="/assets/logotipo.png" alt="logo" />
    <div class="mdc-layout-grid__cell--span-12">
      <mat-tab-group
        animationDuration="0ms"
        (selectedTabChange)="tabClick($event)"
        [selectedIndex]="getSelectedIndexTab()"
      >
        <mat-tab label="Painel de Exames">
          <div class="barra-lateral-dir">
            <app-dashboard-search></app-dashboard-search>
          </div>
          <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-12">
              <mat-card class="filter-card">
                <mat-card-content>
                  <div class="mdc-layout-grid">
                    <div class="mdc-layout-grid__inner">
                      <div class="mdc-layout-grid__cell--span-2">
                        <div class="mdc-layout-grid__cell--span-12">
                          <mat-form-field
                            class="mat-date-range-input mat-form-field-flex w-100"
                          >
                            <mat-label>Prazo do Exame</mat-label>
                            <mat-date-range-input
                              [formGroup]="form"
                              [rangePicker]="picker"
                              [max]="maxDate"
                            >
                              <input
                                matStartDate
                                formControlName="start"
                                placeholder="Data inicial"
                              />
                              <input
                                matEndDate
                                formControlName="end"
                                placeholder="Data final"
                              />
                            </mat-date-range-input>
                            <mat-datepicker-toggle
                              matIconSuffix
                              [for]="picker"
                            ></mat-datepicker-toggle>
                            <mat-date-range-picker
                              #picker
                            ></mat-date-range-picker>
                            @if (
                              form.controls.start.hasError(
                                "matStartDateInvalid"
                              )
                            ) {
                              <mat-error>Invalid start date</mat-error>
                            }
                            @if (
                              form.controls.end.hasError("matEndDateInvalid")
                            ) {
                              <mat-error>Invalid end date</mat-error>
                            }
                          </mat-form-field>
                          <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="1">Hoje</mat-radio-button>
                            <mat-radio-button value="2"
                              >Intervalo</mat-radio-button
                            >
                          </mat-radio-group>
                        </div>
                      </div>
                      <div class="mdc-layout-grid__cell--span-10">
                        <div class="mdc-layout-grid">
                          <div class="mdc-layout-grid__inner">
                            <div class="mdc-layout-grid__cell--span-3">
                              <mat-form-field
                                [formGroup]="form"
                                class="input-text-name-filter mat-form-field-flex w-100"
                              >
                                <mat-label>Paciente</mat-label>
                                <input
                                  class=""
                                  matInput
                                  type="text"
                                  formControlName="pacient"
                                />
                                @if (form.controls["pacient"]) {
                                  <button
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Clear"
                                    (click)="
                                      form.controls['pacient'].setValue('')
                                    "
                                  >
                                    <mat-icon class="material-symbols-outlined"
                                      >cancel</mat-icon
                                    >
                                  </button>
                                }
                              </mat-form-field>
                            </div>
                            <div class="mdc-layout-grid__cell--span-3">
                              <mat-form-field
                                appearance="fill"
                                [formGroup]="form"
                                class="input-text-name-filter mat-form-field-flex w-100"
                              >
                                <mat-label>Médico Executor</mat-label>
                                <input
                                  matInput
                                  type="text"
                                  formControlName="executor_doctor"
                                />
                                @if (form.controls["executor_doctor"]) {
                                  <button
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Clear"
                                    (click)="
                                      form.controls['executor_doctor'].setValue(
                                        ''
                                      )
                                    "
                                  >
                                    <mat-icon class="material-symbols-outlined"
                                      >cancel</mat-icon
                                    >
                                  </button>
                                }
                              </mat-form-field>
                            </div>
                            <div class="mdc-layout-grid__cell--span-3">
                              <mat-form-field
                                [formGroup]="form"
                                class="select-status-filter mat-form-field-flex w-100"
                              >
                                <mat-label>Status</mat-label>
                                <mat-select formControlName="status_study">
                                  <mat-option>Selecione</mat-option>
                                  @for (status of statusList; track status) {
                                    <mat-option [value]="status.id">{{
                                      status.name
                                    }}</mat-option>
                                  }
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="mdc-layout-grid__cell--span-3">
                              <mat-form-field
                                [formGroup]="form"
                                class="input-text-unity-filter mat-form-field-flex w-100"
                              >
                                <mat-label>Instituição</mat-label>
                                <mat-select
                                  matNativeControl
                                  required
                                  formControlName="institution"
                                >
                                  <mat-option>Selecione</mat-option>
                                  @for (
                                    customer of customers$ | async;
                                    track customer
                                  ) {
                                    <mat-option [value]="customer.id">{{
                                      customer.name
                                    }}</mat-option>
                                  }
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                        <div class="mdc-layout-grid__inner">
                          <div class="mdc-layout-grid__cell--span-12">
                            <section [formGroup]="form">
                              <div class="mdc-layout-grid">
                                <div class="mdc-layout-grid__inner">
                                  <div class="mdc-layout-grid__cell--span-4">
                                    <fieldset>
                                      <legend [attr.ref]="'header'">
                                        Prioridades
                                      </legend>
                                      <mat-checkbox
                                        formControlName="emergency"
                                        class="mat-checkbox-inline emergencia"
                                      >
                                        Emergência
                                      </mat-checkbox>
                                      <mat-checkbox
                                        formControlName="urgency"
                                        class="mat-checkbox-inline urgencia"
                                        >Urgência
                                      </mat-checkbox>
                                      <mat-checkbox
                                        formControlName="routine"
                                        class="mat-checkbox-inline rotina"
                                        >Rotina
                                      </mat-checkbox>
                                      <mat-checkbox
                                        formControlName="hospitalized"
                                        class="mat-checkbox-inline hospitalizado"
                                        >Hospitalizado
                                      </mat-checkbox>
                                    </fieldset>
                                  </div>
                                  <div class="mdc-layout-grid__cell--span-8">
                                    <fieldset>
                                      <legend [attr.ref]="'header'">
                                        Exames
                                      </legend>
                                      <mat-checkbox
                                        formControlName="assigned"
                                        class="mat-checkbox-inline"
                                        >Atribuído
                                      </mat-checkbox>
                                      <mat-checkbox
                                        formControlName="assignedToMe"
                                        class="mat-checkbox-inline"
                                        >Atribuído para mim
                                      </mat-checkbox>
                                      <mat-checkbox
                                        formControlName="signed"
                                        class="mat-checkbox-inline"
                                        >Assinado</mat-checkbox
                                      >
                                      <mat-checkbox
                                        formControlName="late"
                                        class="mat-checkbox-inline"
                                        >Atrasado</mat-checkbox
                                      >
                                      <mat-checkbox
                                        formControlName="revaluation"
                                        class="mat-checkbox-inline"
                                        >Em reavaliação
                                      </mat-checkbox>
                                      <mat-checkbox
                                        formControlName="pendency"
                                        class="mat-checkbox-inline"
                                        >Pendência
                                      </mat-checkbox>
                                    </fieldset>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
              <h4>Lista de Exames</h4>
              <div
                class="intevo-infinite-scroll"
                infiniteScroll
                [infiniteScrollDistance]="4"
                [infiniteScrollUpDistance]="1.5"
                [infiniteScrollThrottle]="500"
                (scrolled)="onScrollDown()"
                (scrolledUp)="onScrollUp()"
              >
                <app-dashboard-tab-results
                  [studies$]="studies$"
                ></app-dashboard-tab-results>
              </div>
            </div>
          </div>
        </mat-tab>
        @for (tab of tabs$ | async; track tab) {
          <mat-tab [label]="tab.title">
            <ng-template mat-tab-label>
              {{ tab.title }}
              <button
                mat-mini-fab
                matTooltip="Fechar"
                (click)="tabClose(tab.id)"
                matTooltipPosition="left"
                matBadgePosition="after"
                matBadgeColor="warn"
                color=""
                class="intevo-close-tab-btn"
              >
                x
              </button>
            </ng-template>
            @if (tab.type == "editor") {
              <app-editor [id]="tab.id" [uid]="tab.uid!"></app-editor>
            }
          </mat-tab>
        }
      </mat-tab-group>
    </div>
  </div>
</div>
