import {Component, Input, inject} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Study} from '../../../../model/study';
import {CommonModule} from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import {environment} from "../../../../../environments/environment";
import {TabHeader} from '../../../../model/tab_header';
import {TabService} from '../../../../services/tab.service';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import moment from "moment";

@Component({
  selector: 'app-dashboard-tab-results-item',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    CommonModule,
    MatMenuModule,
    MatDialogModule
  ],
  templateUrl: './dashboard-tab-results-item.component.html',
  styleUrl: './dashboard-tab-results-item.component.scss'
})

export class DashboardTabResultsItemComponent {

  constructor(public dialog: MatDialog) {
  }

  @Input() study: Study | undefined;
  protected readonly environment = environment;
  tabService = inject(TabService);

  openEditorLaudo(study: Study | undefined) {
    const tabeditor: TabHeader = {
      title: typeof study?.patient?.name === 'string' ? study.patient.name : 'Editor',
      id: this.tabService.getPosition(),
      uid: study?.studyInstanceUID,
      inputs: study,
      type: 'editor'
    }
    this.tabService.add(tabeditor);
    this.tabService.setSelectedTab(tabeditor.id);
  }

  openProntuario() {
    this.dialog.open(DialogContentProntuario, {width: '60vw', panelClass: 'custom-mat-dialog-panel'});
  }

  calculateAge(birthDate: string | null | undefined): string {
    if (birthDate === null || birthDate === undefined) {
      return "";
    }

    let today = moment()
    let mdob = moment(birthDate, "YYYY-MM-DD");
    let age = today.diff(mdob, "months");
    let yrs = (age / 12).toFixed(1);
    let months = age % 12;

    return `${parseInt(yrs)} year(s), ${months} month(s) and ${today.format("D")} day(s)`;

  }
}

@Component({
  selector: 'diloag-prontuario',
  templateUrl: '../../../../shared/modal/prontuario-modal.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatIconModule],
})

export class DialogContentProntuario {
}
