<mat-card
  class="results item-result-list"
  [ngClass]="{
    rotina: study?.routine,
    urgencia: study?.urgency,
    emergencia: study?.emergency,
    atrasado_mais_de_1h: study?.late && study?.late_hours! > 1
  }"
>
  <mat-card-content>
    <div class="w-100 em-linha">
      <div class="item-resultado divisoria-dir icon-results">
        <mat-icon
          *ngIf="study?.late"
          aria-hidden="false"
          aria-label="Example home icon"
          fontIcon="clock_loader_90"
          class="emergencial-icon material-symbols-outlined"
          matTooltip="Atraso em 90% do tempo"
          matTooltipPosition="above"
        ></mat-icon>
        <br />
        <mat-icon
          *ngIf="study?.medical"
          aria-hidden="false"
          aria-label="Example home icon"
          fontIcon="lock"
          class="material-symbols-outlined"
          matTooltip="Registro bloqueado"
          matTooltipPosition="above"
        ></mat-icon>
      </div>
      <div class="item-resultado divisoria-ver">
        <label>Entrega em</label>
        <div class="body2">
          {{ study?.studyDate | date : "dd/MM/yyyy-HH:mm" : "+0000" }}
        </div>
        <div class="caption">--dias restantes--</div>
      </div>
      <div class="item-resultado divisoria-ver w-12-5">
        <label>Paciente</label>
        <div class="body2">
          <a routerLink=".">{{ study?.patient?.name | titlecase }}</a>
        </div>
        <div class="caption">{{ calculateAge(study?.patient?.birthDate) }} | {{ study?.patient?.gender }}</div>
      </div>
      <div class="item-resultado divisoria-ver w-12-5">
        <label>Exame</label>
        <div class="body2">{{ study?.description }}</div>
        <div class="caption">{{ study?.studyDate | date : "dd/MM/yyyy" : "+0000" }}</div>
      </div>
      <div class="item-resultado divisoria-ver">
        <label>&nbsp;</label>
        <div class="body1">{{ study?.modality }}</div>
      </div>
      <div class="item-resultado divisoria-ver">
        <label>Série/Img</label>
        <div class="body1" color="primary">{{ study?.seriesInStudy }}/{{ study?.instancesInStudy }}</div>
      </div>
      <div class="item-resultado divisoria-ver">
        <label>Ações</label>
        <div class="acoes-botoes">
          <button
            (click)="openEditorLaudo(study)"
            mat-mini-fab
            matTooltip="Laudar"
            matTooltipPosition="above"
            color="primary"
          >
            <mat-icon class="material-symbols-outlined">deblur</mat-icon>
          </button>
          <a
            mat-mini-fab
            matTooltip="Visualizar Exame"
            matTooltipPosition="above"
            href="{{ environment.view }}/viewer?StudyInstanceUIDs={{
              study?.studyInstanceUID
            }}&InstitutionName={{ study?.institution?.name }}"
            target="_blank"
            color="primary"
            disabled="true"
          >
            <mat-icon class="material-symbols-outlined">visibility</mat-icon>
          </a>
          <button
            (click)="openProntuario()"
            mat-mini-fab
            matTooltip="Prontuário"
            matTooltipPosition="above"
            color="primary"
          >
            <mat-icon class="material-symbols-outlined"
              >clinical_notes</mat-icon
            >
          </button>
          <button
            mat-mini-fab
            matTooltip="Mais Ações"
            matTooltipPosition="above"
            [matMenuTriggerFor]="menu"
            color="primary"
          >
            <mat-icon class="material-symbols-outlined">more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item disabled="true">Solicitar laudo</button>
            <button mat-menu-item disabled="true">Solicitar correção do laudo</button>
            <button mat-menu-item disabled="true">Solicitar reconvocação</button>
            <button mat-menu-item disabled="true">Remover solicitação do laudo</button>
            <button mat-menu-item disabled="true">Inserir prontuário</button>
            <button mat-menu-item disabled="true">Replicar exame</button>
            <button mat-menu-item disabled="true">Atender reconvocação</button>
            <button mat-menu-item disabled="true">Baixar .Zip</button>
            <button mat-menu-item disabled="true">Enviar laudo por e-Mail</button>
            <button mat-menu-item disabled="true">Resultado WEB</button>
            <button mat-menu-item disabled="true">Alterar status do exame</button>
            <button mat-menu-item disabled="true">Excluir exame</button>
            <button mat-menu-item disabled="true">Excluir laudo</button>
          </mat-menu>
        </div>
      </div>
      <div class="item-resultado divisoria-ver acoes-botoes">
        <label>&nbsp; </label>
        <button
          mat-mini-fab
          matTooltip="Baixar Laudo"
          matTooltipPosition="above"
          class="download-button"
          disabled="true"
        >
          <mat-icon class="material-symbols-outlined">browser_updated</mat-icon>
        </button>
      </div>
      <div class="item-resultado divisoria-ver">
        <label>Status do Exame</label>
        <div class="body2">{{ study?.status }}</div>
      </div>
      <div class="item-resultado divisoria-ver">
        <label>Instituição</label>
        <div class="body2">{{ study?.institution?.name | titlecase }}</div>
        <div class="caption">--setor--</div>
      </div>
      <div class="item-resultado">
        <label>Médico(a) Laudo</label>
        <div class="body2">--nome médico--</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
