<menu>
  <a
    routerLink="/"
    routerLinkActive="active-link"
    matTooltip="Home"
    matTooltipPosition="right"
    class="item-menu"
  >
    <mat-icon class="material-symbols-outlined" >garage_door</mat-icon>
  </a>
  <a [routerLink]="['/exam/exam-edit']" matTooltip="Exames" matTooltipPosition="right" >
    <div class="item-menu">
      <mat-icon class="material-symbols-outlined" >clinical_notes</mat-icon>
    </div>
  </a>
  <a routerLink="/" matTooltip="Laudos" class="disabled" matTooltipPosition="right">
    <div class="item-menu">
      <mat-icon class="material-symbols-outlined" >deblur</mat-icon>
    </div>
  </a>
  <a routerLink="/" matTooltip="Relatórios" class="disabled" matTooltipPosition="right">
    <div class="item-menu">
      <mat-icon class="material-symbols-outlined" >summarize</mat-icon>
    </div>
  </a>
  <a routerLink="/" matTooltip="Configurações" class="disabled" matTooltipPosition="right">
    <div class="item-menu">
      <mat-icon class="material-symbols-outlined" >settings</mat-icon>
    </div>
  </a>
  <button *isGranted="permBtnAdmin" mat-button [matMenuTriggerFor]="admin" matTooltipPosition="right" matTooltip="Administração">
    <div class="item-menu">
      <mat-icon class="material-symbols-outlined">deployed_code</mat-icon>
    </div>
  </button>
  <mat-menu #admin="matMenu" yPosition="below" xPosition="after" class="sub-menu-central">
    <h4>Administração</h4>
    <a [routerLink]="['/admin/customer']" >
      <button mat-menu-item>Instituição</button>
    </a>
    <a [routerLink]="['/admin/user']" >
      <button mat-menu-item>Usuário</button>
    </a>
  </mat-menu>
  <a
    routerLink="/"
    (click)="logout()"
    matTooltip="Sair"
    matTooltipPosition="right"
  >
    <div class="item-menu">
      <mat-icon class="material-symbols-outlined">power_settings_new</mat-icon>
    </div>
  </a>
  <a routerLink="/" matTooltip="Expandir" class="disabled" matTooltipPosition="right">
    <div class="item-menu">
      <mat-icon class="material-symbols-outlined">left_panel_open</mat-icon>
    </div>
  </a>
  <a class="perfil" class="disabled">
    <img class="image clip-circle" src="/assets/user.jpg" style="opacity: 30%" />
  </a>
</menu>
<router-outlet></router-outlet>
