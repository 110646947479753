<h2>Exames Anteriores</h2>
<mat-dialog-content class="mat-typography">
  <div><mat-slide-toggle color="primary" hideIcon>Nome original</mat-slide-toggle></div>
  <div><mat-slide-toggle color="primary" hideIcon>Nomes semelhantes</mat-slide-toggle></div>
  <div><mat-slide-toggle color="primary" hideIcon>Código original</mat-slide-toggle></div>
  <div><mat-slide-toggle color="primary" hideIcon>Mesma data de aniversário</mat-slide-toggle></div>
  <div><mat-slide-toggle color="primary" hideIcon>Mesma unidade</mat-slide-toggle></div>
  <div><mat-slide-toggle color="primary" hideIcon>Meus exames</mat-slide-toggle></div>
  <div><mat-slide-toggle color="primary" hideIcon>Mesma modalidade</mat-slide-toggle></div>
  <hr>
  <mat-radio-group aria-label="Select an option">
    <mat-radio-button value="1" class="body2" color="primary">1 mês</mat-radio-button>
    <mat-radio-button value="2" class="body2" color="primary">1 ano</mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-fab extended color="surface" [mat-dialog-close]="true" cdkFocusInitial class="md-medium">
    Fechar
    <mat-icon class="material-symbols-outlined">close</mat-icon>
  </button>
  <button mat-fab extended color="accent" [mat-dialog-close]="true" cdkFocusInitial class="md-medium">
    Filtrar
    <mat-icon class="material-symbols-outlined">filter_alt</mat-icon>
  </button>
</mat-dialog-actions>