import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Page } from "../model/page";
import { environment } from "../../environments/environment";
import { Pageable } from "../model/pageable";
import { BaseService } from "./base.service";
import { Template } from "../model/template";

@Injectable({
  providedIn: "root",
})
// export class LaudosService {
export class TemplateService extends BaseService<Template> {
  constructor(protected override http: HttpClient) {
    super(http);
  }
  public getJSON(): Observable<any> {
    return this.http.get("../../assets/templates.json");
  }

  searchTemplates(
    customerID: string,
    modality: string,
    page: Pageable,
  ): Observable<Page<Template>> {
    let params = new HttpParams()
      .set("offset", page.offset | 1)
      .set("limit", page.limit)
      .set("InstitutionId", customerID)
     .set("Modality", modality);

    return this.get(
      `${environment.dcm}/his/studies/medical-report/templates`,
      params,
    );
  }
}
