import { Component, OnInit, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardMenuComponent } from "../../../dashboard/dashboard-menu/dashboard-menu.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatOptionModule } from "@angular/material/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../../../../shared/modal/confirm-dialog/confirm-dialog.component";
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { AlertService } from "../../../../services/alert.service";
import { CustomerService } from "../../../../services/customer.service";
import { Customer } from "../../../../model/customer";
import { Router, RouterLink, ActivatedRoute} from "@angular/router";
import { Observable, of } from "rxjs";

@Component({
  selector: "app-customer-edit",
  standalone: true,
  imports: [
    CommonModule,
    DashboardMenuComponent,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    ReactiveFormsModule,
    RouterLink,
  ],
  templateUrl: "./customer-edit.component.html",
  styleUrl: "./customer-edit.component.scss",
})
export class CustomerEditComponent implements OnInit {
  isDisabled: boolean = false;
  form: FormGroup;
  customerService = inject(CustomerService);
  alertService = inject(AlertService);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);

  customer$: Observable<Customer> = of();

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {
    this.form = this.formBuilder.group({
      id: [null, Validators.required],
      name: [null, Validators.required],
      description: [null, Validators.required],
      document: [null, Validators.required],
      roles: [null],
      address: this.formBuilder.group({
        street: [null, Validators.required],
        city: [null, Validators.required],
        state: [null, Validators.required],
        zipCode: [null, Validators.required],
      }),
      owner: this.formBuilder.group({
        name: [null, Validators.required],
        email: [null, Validators.required],
        telephone: [null, Validators.required],
      }),
      manager: this.formBuilder.group({
        name: [null, Validators.required],
        email: [null, Validators.required],
        document: [null],
        telephone: [null, Validators.required],
      }),
      sla: this.formBuilder.group({
        hospitalized: [null, Validators.required],
        emergency: [null, Validators.required],
        urgency: [null, Validators.required],
        routine: [null, Validators.required],
      }),
      bucketLifecyclePolicy: [null, Validators.required],
    });
  }

  ngOnInit() {
    const customerId = this.activatedRoute.snapshot.paramMap.get("id");
    this.customerService.load(customerId!).subscribe({
      next: (customer: Customer) =>{
          this.customer$ = of(customer)
          this.form.patchValue(customer);
      },
      error: (e) => {
         console.log(e);
      }
    });
  }

  onSubmit(): void {
    if (this.form.invalid) {
      console.log("invalido");
      console.log(this.form);
      return;
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: "350px",
        data: { message: "Tem certeza que deseja alterar esta instituição" },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const formData = this.form.value; // Get form data
          this.customerService.update(formData).subscribe(
            (editedCustomer) => {
              this.alertService.openSnackBar('Salvo com sucesso!');
              this.router.navigate(['/admin/customer']);
              console.log(editedCustomer);
            },
            (error) => {
              this.alertService.openSnackBar('Um erro ocorreu ao tentar salvar.');
              console.log("error");
              console.error(error);
            }
          );
        }
      });
    }
  }
}
