export const environment = {
  dcm: "https://api.intevo.queiroz.dev/v1/dicom",
  iam: "https://api.intevo.queiroz.dev/v1",
  auth: "https://auth.intevo.queiroz.dev",
  view: "https://view.intevo.queiroz.dev",
  realm: "dcm4che",
  clientId: "dcm4chee-arc-ui",
  production: false,
};

