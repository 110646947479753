import {KeycloakBearerInterceptor, KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {ApplicationConfig, LOCALE_ID, APP_INITIALIZER, Provider} from "@angular/core";
import {provideNoopAnimations} from "@angular/platform-browser/animations";
import {provideRouter, withHashLocation} from "@angular/router";
import {environment} from "../environments/environment";
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {appRoutes} from "./app-routing.module";

registerLocaleData(localePt);

function initializeKeycloak(keycloak: KeycloakService) {
  return () => keycloak.init({
    config: {
      url: environment.auth,
      realm: environment.realm,
      clientId: environment.clientId,
    },
    initOptions: {
      silentCheckSsoRedirectUri:
        window.location.origin + "/assets/silent-check-sso.html" // URI for silent SSO checks
    },
    loadUserProfileAtStartUp: true,
    enableBearerInterceptor: true,
    bearerExcludedUrls: ["/assets", "/clients/public"],
    shouldAddToken: (request) => {
      if (request.headers.has('X-Skip-Interceptor')) {
        request.headers.delete('X-Skip-Interceptor');
        return false;
      }

      return true;
    },
    shouldUpdateToken(request) {
      const tokenUpdated = request.headers.get("token-update")
      return !(tokenUpdated != null && tokenUpdated === "false");
    },
  });
}

// Provider for Keycloak Bearer Interceptor
const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true
};


// Provider for Keycloak Initialization
const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService]
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    KeycloakAngularModule,
    KeycloakService,
    KeycloakInitializerProvider,
    KeycloakBearerInterceptorProvider,
    provideHttpClient(),
    provideNoopAnimations(),
    {
      provide: LOCALE_ID,
      useValue: "pt-BR"
    },
    provideRouter(appRoutes, withHashLocation())
  ]
};
