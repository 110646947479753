<router-outlet></router-outlet>
<div class="mdc-layout-grid">
    <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell--span-12 erro-sistema">
            <img class="logo" src="/assets/erro-404.jpg"/>
            <h1>Página não encontrada</h1>
            <p>A página que você está procurando pode ter sido removida ou está temporariamente indisponível.</p>
            <button mat-fab extended [routerLink]="['/']">
                Voltar ao início
            </button>
        </div>
    </div>
</div>
