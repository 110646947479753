import { Component, InjectionToken } from '@angular/core';
import { JsonPipe} from '@angular/common';
import { MatIconModule} from '@angular/material/icon';
import { MatTooltipModule} from '@angular/material/tooltip';
import { KeycloakService } from "keycloak-angular";
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { DirectivesModule } from "../../../directives/directives.module";

interface Status {
  id: string;
  name: string;
}

@Component({
  selector: 'app-dashboard-menu',
  standalone: true,
  imports: [
    JsonPipe,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    DirectivesModule,
  ],
  templateUrl: './dashboard-menu.component.html',
  styleUrl: './dashboard-menu.component.scss'
})
export class DashboardMenuComponent {
  //Perms
  permBtnAdmin: string[] = ['ADMINISTRADOR','ROOT'];

  constructor(private kc: KeycloakService) {}

  logout(): void {
    this.kc.logout();
  }
}
