<div class="box-barra-dir">
  <button
    mat-mini-fab
    matTooltip="Minimizar Pesquisa"
    matTooltipPosition="left"
    color="primary"
    disabled="true"
  >
    <mat-icon class="material-symbols-outlined">zoom_out</mat-icon>
  </button>
  <button
    mat-mini-fab
    matTooltip="Limpar Pesquisa"
    matTooltipPosition="left"
    color="primary"
    disabled="true"
  >
    <mat-icon class="material-symbols-outlined">cleaning_services</mat-icon>
  </button>
  <button
    mat-mini-fab
    matTooltip="Visualizar em Cartões"
    matTooltipPosition="left"
    color="primary"
    disabled="true"
  >
    <mat-icon class="material-symbols-outlined">apps</mat-icon>
  </button>
</div>
<div class="exame-search">
  <button
    mat-mini-fab
    matTooltip="Selecionar Todos"
    matTooltipPosition="left"
    color="primary"
    disabled="true"
  >
    <mat-icon class="material-symbols-outlined">ballot</mat-icon>
  </button>
  <button
    mat-mini-fab
    matBadge="0"
    matBadgePosition="after"
    matBadgeColor="warn"
    color="primary"
    disabled="true"
  >
    CR
  </button>
  <button mat-mini-fab color="primary" disabled="true">CT</button>
  <button mat-mini-fab color="primary" disabled="true">DX</button>
  <button
    mat-mini-fab
    mat-mini-fab
    matBadge="0"
    matBadgePosition="after"
    matBadgeColor="warn"
    color="primary"
    disabled="true"
  >
    MG
  </button>
  <button
    mat-mini-fab
    mat-mini-fab
    matBadge="0"
    matBadgePosition="after"
    matBadgeColor="warn"
    color="primary"
    disabled="true"
  >
    MR
  </button>
  <button mat-mini-fab color="primary" disabled="true">PT</button>
  <button mat-mini-fab color="primary" disabled="true">SR</button>
  <button
    mat-mini-fab
    mat-mini-fab
    matBadge="0"
    matBadgePosition="after"
    matBadgeColor="warn"
    color="primary"
    disabled="true"
  >
    US
  </button>
  <button
    mat-mini-fab
    mat-mini-fab
    matBadge="0"
    matBadgePosition="after"
    matBadgeColor="warn"
    color="primary"
    disabled="true"
  >
    XA
  </button>
</div>
