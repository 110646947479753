import {
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  inject,
  OnInit,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { DashboardTabResultsComponent } from "./dashboard-tab-results/dashboard-tab-results.component";
import { DashboardMenuComponent } from "./dashboard-menu/dashboard-menu.component";
import { DashboardSearchComponent } from "./dashboard-search/dashboard-search.component";
import { EditorComponent, TemplateLaudoList } from "../editor/editor.component";
import { Study } from "../../model/study";
import { TabHeader } from "../../model/tab_header";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Observable, of, Subject, timer, tap } from "rxjs";
import { takeUntil, throttleTime, distinctUntilChanged, debounceTime} from "rxjs/operators";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { StudyService } from "../../services/study.service";
import { UserService } from "../../services/user.service";
import { TabService } from "../../services/tab.service";
import { Page } from "../../model/page";
import { User } from "../../model/user";
import { Customer } from "../../model/customer";
import { Pageable } from "../../model/pageable";
import { JsonPipe, NgComponentOutlet } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatOptionModule } from "@angular/material/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { StudyFilter } from "../../model/study_filter";
import { CommonModule } from "@angular/common";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ActivatedRoute } from "@angular/router";

interface Status {
  id: string;
  name: string;
}

@Component({
  selector: "app-dashboard",
  standalone: true,
  imports: [
    // DashboardTabsComponent,
    DashboardTabResultsComponent,
    DashboardMenuComponent,
    DashboardSearchComponent,
    JsonPipe,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    ReactiveFormsModule,
    NgComponentOutlet,
    EditorComponent,
    CommonModule,
    InfiniteScrollModule,
  ],
  templateUrl: "./dashboard.component.html",
  styleUrl: "./dashboard.component.scss",
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) private _paginator: MatPaginator | undefined;
  @ViewChild(MatSort) private _sort: MatSort | undefined;
  @ViewChild(EditorComponent) private _resfresh_editor:
    | EditorComponent
    | undefined;
  @ViewChildren(EditorComponent) editors!: QueryList<EditorComponent>;

  studyService = inject(StudyService);
  studies$: Observable<Study[]> = of();
  cacheStudies: Study[] | undefined;

  offset: number = 1;
  limit: number = 10;
  page: Page<Study> | undefined;
  pages: Page<Study>[] | undefined;
  maxDate = new Date();

  userService = inject(UserService);
  user$: Observable<User> = of();
  customers$: Observable<Customer[]> = of();

  isDisabled: boolean = false;
  form = new FormGroup({
    pacient: new FormControl<string>(""),
    executor_doctor: new FormControl<string>(""),
    status_study: new FormControl<string>(""),
    institution: new FormControl<string>(""),
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
    emergency: new FormControl<boolean>(false),
    urgency: new FormControl<boolean>(false),
    routine: new FormControl<boolean>(false),
    hospitalized: new FormControl<boolean>(false),
    assigned: new FormControl<boolean>(false),
    assignedToMe: new FormControl<boolean>(false),
    signed: new FormControl<boolean>(false),
    late: new FormControl<boolean>(false),
    late_hours: new FormControl<number>(2),
    revaluation: new FormControl<boolean>(false),
    pendency: new FormControl<boolean>(false),
  });

  tabService = inject(TabService);
  tabs$: Observable<TabHeader[]> = of();
  activatedRoute = inject(ActivatedRoute);

  private _block: boolean = false;

  statusList: Status[] = [
    { id: "CREATED", name: "Pronto para Laudo" },
    { id: "CANCELED", name: "Cancelado" },
    { id: "DRAFT", name: "Em Rascunho" },
    { id: "FIXES", name: "Com Error" },
    { id: "REJECTED", name: "Rejeitado" },
    { id: "REPORTED", name: "Laudado" },
    { id: "SIGNED", name: "Assinado" },
    { id: "UNDER_REASSESSMENT", name: "Em Reavaliação" },
  ];

  private booking: any;
  private recallTimer = 60; //segundos

  private destroyed = new Subject<void>();

  ngOnInit() {
    const idlaudo = this.activatedRoute.snapshot.paramMap.get("idlaudo");
    if (idlaudo != null) {
      const cid = this.activatedRoute.snapshot.paramMap.get("cid");
      this.loadTabs(idlaudo, cid!);
    }

    //TODO remover temporario
    this.controlFormState();
    // this.process({
    //   sort: "uploadDate,desc",
    //   offset: this.offset,
    //   limit: this.limit
    // });
    this.tabs$ = of(this.tabService.getAll());

    this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
        throttleTime(1000)
      )
      .subscribe((studyFilter) => {
      this.offset = 1;
      let pageable: Pageable = {
        sort: "uploadDate,desc",
        offset: this.offset,
        limit: this.limit,
      };
      if (!this.isDisabled) {
        this.isDisabled = true;
        //this.formDisableControl();
        this.studyService
          .searchStudies(studyFilter as StudyFilter, pageable)
          .pipe(takeUntil(this.destroyed))
          .subscribe({
            next: (page: Page<Study>) => {
              this.controlFormState();
              if (this._paginator) {
                this._paginator.length = page.total;
              }
              this.page = page;

              if (this.page != null) {
                this.studies$ = of(this.page.content);
                this.cacheStudies = this.page.content;
              } else {
                this.studies$ = of();
              }
              console.log(page);
            },
            error: (e) => {
              console.error(e);
              this.controlFormState();
              this.isDisabled = false;
            },
            complete: () => {
              console.info("complete");
              this._block = false;
              this.controlFormState();
              this.isDisabled = false;
              if (!this.booking) {
                this.bookingUpdateList(this.recallTimer * 1000);
              }
            },
          });
      }
    });
    this.userService
      .loadUserLogged()
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (user: User) => {
          this.user$ = of(user);
          if (user.customers != null) {
            this.customers$ = of(user.customers);
            if (user.customers.length === 1) {
              this.form.get("institution")?.setValue(user.customers[0]?.id);
            }
          }
        },
        error: (e) => {
          console.log(e);
        },
      });
  }
  tabClick($event: any) {
    this.tabService.setSelectedTab($event.index);
    //ignora tab default
    const index = $event.index - 1;

    this.editors.map((e) => {
      //ignora tab inicial
      if (index >= 0) {
        const listtabs = this.tabService.getAll();
        if (e.id == listtabs[index].id && listtabs[index].type == "editor") {
          e.refreshEditor();
        }
      }
    });
  }
  tabClose(id: number) {
    this.tabService.removeById(id);
    this.tabs$ = of(this.tabService.getAll());
  }
  getSelectedIndexTab(): number {
    return this.tabService.getSelectedTab();
  }

  private formDisableControl() {
    this.form.disable();
  }

  private controlFormState() {
    this.form.enable();
    this.form.get("assigned")?.disable();
    this.form.get("assignedToMe")?.disable();
    this.form.get("signed")?.disable();
    this.form.get("late")?.disable();
    this.form.get("late_hours")?.disable();
    this.form.get("revaluation")?.disable();
    this.form.get("pendency")?.disable();
  }

  bookingUpdateList(val: number): void {
    this.booking = timer(val)
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.syncStudiesList();
        this.bookingUpdateList(this.recallTimer * 1000);
      });
  }

  syncStudiesList(): void {
    let total = 0;
    if (this.cacheStudies != undefined) {
      total = this.cacheStudies.length;
    }
    //recalcula limit para praginas fracionadas
    const limit =
      total % this.limit
        ? total + (this.limit - (total % this.limit))
        : total + this.limit;
    let pageable: Pageable = {
      sort: "uploadDate,desc",
      offset: 1,
      limit: limit,
    };
    const studyFilter = this.form.value!;
    this.studyService
      .searchStudies(studyFilter as StudyFilter, pageable)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (page: Page<Study>) => {
          if (this._paginator) {
            this._paginator.length = page.total;
          }
          if (page != null) {
            this.studies$ = of(page.content);
            this.cacheStudies = page.content;
          } else {
            this.studies$ = of();
          }
          console.log(page);
        },
        error: (e) => {},
        complete: () => {
          console.info("complete");
        },
      });
  }

  onScrollDown() {
    //verificar pagina atual
    let total = 0;
    if (this.cacheStudies != undefined) {
      total = this.cacheStudies.length;
    }
    const offset = total + 1;
    const limit =
      total % this.limit
        ? this.limit + (this.limit - (offset % this.limit))
        : this.limit;
    console.log(`limit ${limit} `);
    let pageable: Pageable = {
      sort: "uploadDate,desc",
      offset: offset,
      limit: limit,
    };
    const studyFilter = this.form.value!;
    this.studyService
      .searchStudies(studyFilter as StudyFilter, pageable)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (page: Page<Study>) => {
          if (this._paginator) {
            this._paginator.length = page.total;
          }
          this.page = page;
          if (this.page != null) {
            if (this.cacheStudies == undefined) {
              this.studies$ = of(this.page.content);
              this.cacheStudies = this.page.content;
            } else {
              this.studies$ = of([...this.cacheStudies, ...this.page.content]);
              this.cacheStudies = [...this.cacheStudies, ...this.page.content];
            }
          } else if (this.cacheStudies == undefined) {
            this.studies$ = of();
          }
        },
        error: (e) => {},
        complete: () => {
          console.info("complete");
        },
      });
  }

  onScrollUp() {
    //this.pagenumber -= 1;
  }
  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.throttledCheckItemsInView();
  }
  throttledCheckItemsInView(): void {
    timer(500)
      .pipe(takeUntil(this.destroyed), throttleTime(500))
      .subscribe(() => {
        this.checkItemsInview();
      });
  }
  checkItemsInview(): void {
    let listItems = document.querySelectorAll(".item-result-list");
    let currentInView: HTMLElement | null = null;
    let currentIndex: number = 0;

    listItems.forEach((item, index) => {
      const rect = item.getBoundingClientRect();
      const viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight,
      );

      if (!(rect.bottom < 0 || rect.top - viewHeight >= 0)) {
        currentInView = item as HTMLElement;
        currentIndex = index;
      }
    });

    if (currentInView) {
      console.log("Currently viewing:", currentIndex);
      // Custom logic here, e.g., update UI, highlight item, etc.
    }
  }

  loadTabs(idlaudo: string, cid: string): void {
    this.studyService
      .loadStudyById(idlaudo.replaceAll("-", "."), cid.replaceAll("-", "."))
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (study) => {
          const tabeditor: TabHeader = {
            title:
              typeof study?.patient?.name === "string"
                ? study.patient.name
                : "Editor",
            id: this.tabService.getPosition(),
            uid: study?.studyInstanceUID,
            inputs: study,
            type: "editor",
          };
          this.tabService.add(tabeditor);
          this.tabService.setSelectedTab(tabeditor.id);
        },
        (error) => {
          console.log(`error ao carrega tab: ${error}`);
        },
      );
  }
  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
