import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { StudyFilter } from "../model/study_filter";
import { Pageable } from "../model/pageable";
import { Injectable, inject } from "@angular/core";
import { BaseService } from "./base.service";
import { Study } from "../model/study";
import { Page } from "../model/page";
import { Observable } from "rxjs";
import { StringToBlobService } from "./stringtoblob.service";

@Injectable({
  providedIn: "root",
})
export class ReportService extends BaseService<Study> {

  stringToBlobService = inject(StringToBlobService);
  constructor(protected override http: HttpClient) {
    super(http);
  }

  loadReport(customerID: string, studyUID: string): Observable<Blob> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/stream+json");
    return this.getBlob(
      `${environment.dcm}/his/${customerID}/studies/${studyUID}/medical-report`,
      new HttpParams(),
      { headers: headers },
    );
  }

  saveReport(
    customerID: string,
    studyUID: string,
    body: string,
  ): Observable<Blob> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/stream+json");

    //const body = this.strigToBlobService.stringToBlob(btoa(this.editor.getContent()));
    return this.post(
      `${environment.dcm}/his/${customerID}/studies/${studyUID}/medical-report`,
      this.stringToBlobService.stringToBlob(body),
      new HttpParams(),
      { headers: headers },
    );
  }

  updateReport(
    customerID: string,
    studyUID: string,
    reportID: string,
    body: string,
  ): Observable<Blob> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/stream+json");

    return this.put(
      `${environment.dcm}/his/${customerID}/studies/${studyUID}/medical-report/${reportID}`,
      this.stringToBlobService.stringToBlob(body),
      new HttpParams(),
      { headers: headers },
    );
  }

  downloadReport(
    cid: string,
    studyUID: string,
    reportID: string,
  ): Observable<Blob> {
    const headers = new HttpHeaders()
      .set("Accept", "application/pdf")
      .set("Content-Type", "application/json");
    return this.getBlob(
      `${environment.dcm}/his/${cid}/studies/${studyUID}/medical-report/${reportID}/download`,
      new HttpParams(),
      { headers: headers },
    );
  }

  getImage(url: string): Observable<Blob> {
    const headers = new HttpHeaders().set("X-Skip-Interceptor", "true");
    return this.getBlob(url, new HttpParams(), { headers: headers });
  }
}
