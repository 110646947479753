import { Component, OnInit } from '@angular/core';
import { KeycloakService } from "keycloak-angular";
import { Router } from "@angular/router";

@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [],
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss'
})
export class LogoutComponent implements OnInit {

  constructor(private kc: KeycloakService, private router: Router) {}

  ngOnInit(): void {
    this.kc.logout();
    this.router.navigate(['/']);
  }
}
