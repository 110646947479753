import { Component, inject, Injectable, OnInit, Input } from "@angular/core";
import { EditorModule } from "@tinymce/tinymce-angular";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { Editor, default as tiny } from "tinymce";
import { MatListModule } from "@angular/material/list";
import { MatRadioModule } from "@angular/material/radio";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import {
  FormGroup,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { AsyncPipe } from "@angular/common";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Study } from "../../model/study";
import { Template } from "../../model/template";
import { TabHeader } from "../../model/tab_header";
import { TabService } from "../../services/tab.service";
import { TemplateService } from "../../services/template.service";
import { StudyService } from "../../services/study.service";
import { AlertService } from "../../services/alert.service";
import { ReportService } from "../../services/report.service";
import { MatBadgeModule } from "@angular/material/badge";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Pageable } from "../../model/pageable";
import { Page } from "../../model/page";

export interface TemplateLaudoList {
  title: string;
  body: string;
  category: string;
}

@Component({
  selector: "app-editor",
  standalone: true,
  imports: [
    AsyncPipe,
    EditorModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCardModule,
    MatListModule,
    MatRadioModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatTooltipModule,
  ],
  templateUrl: "./editor.component.html",
  styleUrl: "./editor.component.scss",
})
export class EditorComponent implements OnInit {
  @Input() id!: number;
  templatesLaudos: Template[] = [];
  @Input() uid!: string;
  editor: any;
  elementAreaId: string = "";
  editorSettings = {};
  secondtime: boolean = false;
  searchInput = new FormControl<string | Template>("");
  filteredOptions?: Observable<Template[]>;
  tabService = inject(TabService);
  study!: Study | undefined;
  templateService = inject(TemplateService);
  studyService = inject(StudyService);
  reportId: string | null = null;

  btnSalvarDisabled: boolean = false;
  btnDownloadDisabled: boolean = false;
  alertService = inject(AlertService);
  reportService = inject(ReportService);

  limitTemplate = 10;
  offsetTemplate = 1;

  ngOnInit() {
    this.setEditorParams();
    const tabheader = this.tabService.getTabByUID(this.uid);
    this.study = tabheader?.inputs;

    if (
      typeof this.study?.institution?.id == "string" &&
      typeof this.study?.studyInstanceUID == "string"
    ) {
      let pageable: Pageable = {
        sort: "uploadDate,desc",
        offset: this.offsetTemplate,
        limit: this.limitTemplate,
      };
      let modality = this.study.modality == null?"":this.study.modality;
      this.templateService
        .searchTemplates(this.study.institution.id, modality, pageable)
        .subscribe({
          next: (page: Page<Template>) => {
            this.templatesLaudos = page.content;
            this.filteredOptions = this.searchInput.valueChanges.pipe(
              startWith(""),
              map((value) => {
                const title =
                  typeof value === "string" ? value : value?.description;
                const body = typeof value === "string" ? "" : value?.content;
                this.insertTemplate(body as string);
                return title
                  ? this._filter(title as string)
                  : this.templatesLaudos.slice();
              }),
            );
            console.log(this.templatesLaudos);
          },
        });
    }
  }

  constructor(public dialog: MatDialog) {}

  setEditorParams() {
    this.editorSettings = {
      base_url: "/tinymce", // Root for resources
      suffix: ".min", // Suffix to use when loading resources
      selector: "textarea",
      id: "editor1",
      menubar: false,
      //plugins: 'lists link image table code help wordcount',
      license_key: "gpl",
      plugins:
        "ai advlist anchor code autolink autosave charmap codesample directionality emoticons help image insertdatetime link lists media nonbreaking pagebreak searchreplace table visualblocks visualchars wordcount",
      toolbar:
        "undo redo spellcheckdialog | aidialog aishortcuts | blocks fontfamily fontsizeinput | bold italic underline forecolor backcolor | link image addcomment showcomments  | align lineheight checklist bullist numlist | indent outdent | inserttemplate | removeformat typography math code",
      image_uploadtab: true,
      setup: (editor: Editor) => {
        editor.on("init", (e) => {
          console.log("The Editor has initialized.");
          this.initAfterEditor();
        });
        //TODO modificar quando for ajustar o a funcao de colar imagens de outro dominio.
        //editor.on("PastePostProcess", (e) => {
        //  const content = e.node.innerHTML;
        //  const doc = new DOMParser().parseFromString(content, "text/html");
        //  //const images = e.node.getElementsByTagName("img");
        //  const images = doc.querySelectorAll("img");

        //  for (let i = 0; i < images.length; i++) {
        //    const img = images[i];
        //    if (img.src && !img.src.startsWith("data:")) {
        //      fetch(img.src, { mode: "no-cors" }).then((response) => {
        //        response.blob().then((blob) => {
        //          const reader = new FileReader();
        //          reader.readAsDataURL(blob);
        //          const localUrl = URL.createObjectURL(blob);
        //          reader.onload = () => {
        //            if (typeof reader.result === "string") {
        //              img.src = localUrl;
        //              console.log(localUrl);
        //              //e.node.innerHTML = doc.body.innerHTML;
        //              const modifiedHTML = doc.documentElement.innerHTML;

        //              // Set the modified content back to the editor
        //              e.node.innerHTML = modifiedHTML;
        //              editor.nodeChanged(); // Notify TinyMCE of the change

        //            }
        //          };
        //          reader.onerror = (error) => {
        //            console.log("Error: " + error);
        //          };
        //        });
        //      });
        //    }
        //  }
        //});
        this.editor = editor;
      },
      height: 600,
      paste_data_images: true,
      images_upload_handler: function (
        blobInfo: any,
        success: any,
        failure: any,
      ) {
        let isSuccess = false;
        const reader = new FileReader();
        reader.readAsDataURL(blobInfo.blob());
        const localUrl = URL.createObjectURL(blobInfo.blob());
        reader.onload = () => {
          if (typeof reader.result === "string") {
            //success(blobInfo.filename());
            isSuccess = true;
            //success();
          } else {
            failure("Could not convert image to base64");
          }
        };
        reader.onerror = (error) => {
          failure("Error: " + error);
        };
        return new Promise((resolve, reject) => {
          if (isSuccess) {
            resolve(blobInfo.result);
          } else {
            reject();
          }
        });
      },
      images_dataimg_filter: function (img: any) {
        const src = img.getAttribute("src");
        return src?.startsWith("data:") ?? false;
      },
      content_style: `
        body {
          background: #fff;
        }

        /* Disable the blue "focus" border for the editable region */
        .editable-section:focus-visible {
          outline: none !important;
        }

        .header,
        .footer {
          font-size: 0.8rem;
          color: #ddd;
        }

        .header {
          display: flex;
          justify-content: space-between;
          padding: 0 0 1rem 0;
        }

        .header .right-text {
          text-align: right;
        }

        .footer {
          padding:2rem 0 0 0;
          text-align: center;
        }

        /* Apply page-like styling */
        @media (min-width: 840px) {
          html {
            background: #eceef4;
            min-height: 100%;
            padding: 0.5rem;
          }

          body {
            background-color: #fff;
            box-shadow: 0 0 4px rgba(0, 0, 0, .15);
            box-sizing: border-box;
            margin: 1rem auto 0;
            max-width: 820px;
            min-height: calc(100vh - 1rem);
            padding: 2rem 6rem 2rem 6rem;
          }
        }
      `,
    };
  }

  initAfterEditor(): void {
    if (
      typeof this.study?.institution?.id == "string" &&
      typeof this.study?.studyInstanceUID == "string"
    ) {
      this.reportService
        .loadReport(this.study.institution.id, this.study.studyInstanceUID)
        .subscribe({
          next: (blob: Blob) => {
            this.blobToString(blob).subscribe({
              next: (text) => {
                let textJson = JSON.parse(text);
                this.reportId = textJson.id;
                this.insertTemplate(atob(textJson.content));
              },
              error: (error) => {
                console.error("Error converting Blob to string", error);
              },
            });
          },
          error: (error) => {
            console.log("not found");
          },
        });
    }
  }

  insertTemplate(template: string) {
    if (this.editor !== undefined && template != "") {
      this.editor.setContent(template);
    }
  }
  /**
   * This function fix issue in editor on pagination tab
   */
  public refreshEditor() {
    if (this.secondtime) {
      tiny.execCommand("mceRemoveEditor", false, this.elementAreaId);
      setTimeout(() => {
        new tiny.Editor(
          this.elementAreaId,
          this.editorSettings,
          tiny.EditorManager,
        ).render();
      }, 5);
    } else {
      this.elementAreaId = this.editor.targetElm.id;
      this.secondtime = true;
    }
  }

  openExamesAnteriores() {
    const dialogRef = this.dialog.open(DialogContentExamesAnteriores);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openProntuario() {
    this.dialog.open(DialogContentProntuario, {
      width: "60vw",
      panelClass: "custom-mat-dialog-panel",
    });
  }
  openComentarios() {
    this.dialog.open(DialogContentComentarios, {
      width: "50vw",
      panelClass: "custom-mat-dialog-panel",
    });
  }
  displayTemplate(template: Template): string {
    return template && template.description ? template.description : "";
  }

  private _filter(title: string): Template[] {
    const filterValue = title.toLowerCase();

    return this.templatesLaudos.filter((option) =>
      option.description.toLowerCase().includes(filterValue),
    );
  }

  saveStudy(): void {
    if (
      typeof this.study?.institution?.id == "string" &&
      typeof this.study?.studyInstanceUID == "string"
    ) {
      const body = btoa(this.editor.getContent());
      this.btnSalvarDisabled = true;
      if (this.reportId == null) {
        this.reportService
          .saveReport(
            this.study.institution.id,
            this.study.studyInstanceUID,
            body,
          )
          .subscribe({
            next: (body) => {
              console.log(body);
              this.alertService.openSnackBar("Salvo com sucesso!");
            },
            error: (error) => {
              this.alertService.openSnackBar(
                "Um erro ocorreu ao tentar salvar.",
              );
            },
            complete: () => {
              this.btnSalvarDisabled = false;
            },
          });
      } else {
        //UPDATE
        this.reportService
          .updateReport(
            this.study.institution.id,
            this.study.studyInstanceUID,
            this.reportId,
            body,
          )
          .subscribe({
            next: (body) => {
              console.log(body);
              this.alertService.openSnackBar("Salvo com sucesso!");
            },
            error: (error) => {
              this.alertService.openSnackBar(
                "Um erro ocorreu ao tentar salvar.",
              );
            },
            complete: () => {
              this.btnSalvarDisabled = false;
            },
          });
      }
    }
  }
  downloadReport(): void {
    if (
      typeof this.study?.institution?.id == "string" &&
      typeof this.study?.studyInstanceUID == "string" &&
      this.reportId != null
    ) {
      this.btnDownloadDisabled = true;
      this.reportService
        .downloadReport(
          this.study.institution.id,
          this.study.studyInstanceUID,
          this.reportId!, //TODO set the reportid
        )
        .pipe()
        .subscribe({
          next: (res) => {
            let url = window.URL.createObjectURL(res);
            let a = document.createElement("a");
            a.href = url;
            a.download = "Download pdf";
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
          },
          error: (error) => {
            console.log(error);
          },
          complete: () => {
            this.btnDownloadDisabled = false;
          },
        });
    } else {
      this.alertService.openSnackBar(
        "Erro ao tentar fazer o download, certifique-se que o laudo foi salvo.",
      );
    }
  }

  //blob
  private blobToString(blob: Blob): Observable<string> {
    return new Observable<string>((observer) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        observer.next(reader.result as string);
        observer.complete();
      };

      reader.onerror = () => {
        observer.error(new Error("Erro ao tentar converter para string"));
      };

      reader.readAsText(blob);
    });
  }
}
@Component({
  selector: "diloag-exames-anteriores",
  templateUrl: "../../shared/modal/exames-anteriores-modal.html",
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatRadioModule,
  ],
})
export class DialogContentExamesAnteriores {}

@Component({
  selector: "diloag-prontuario",
  templateUrl: "../../shared/modal/prontuario-modal.html",
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatIconModule],
})
export class DialogContentProntuario {}

@Component({
  selector: "diloag-comentarios",
  templateUrl: "../../shared/modal/comentarios-modal.html",
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
  ],
})
export class DialogContentComentarios {}
