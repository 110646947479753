<div class="menu">
  <app-dashboard-menu></app-dashboard-menu>
</div>
<div class="mdc-layout-grid">
  <div class="mdc-layout-grid__inner">
    <img class="logo" src="/assets/logotipo.png" alt="logo" />
    <div class="mdc-layout-grid__cell--span-12">
      <mat-tab-group animationDuration="0ms">
        <mat-tab *ngIf="user$ | async as data" label="{{ data.name }}">
          <form [formGroup]="form">
            <!--        <div class="barra-lateral-dir"></div>   -->
            <div class="mdc-layout-grid__inner">
              <div class="mdc-layout-grid__cell--span-6">
                <h3>Informações</h3>
                <mat-card class="filter-card">
                  <mat-card-content>
                    <div class="mdc-layout-grid">
                      <div class="mdc-layout-grid__inner">
                        <div class="mdc-layout-grid__cell--span-4">
                          <mat-form-field
                            class="input-text-name-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Nome</mat-label>
                            <input
                              class=""
                              matInput
                              type="text"
                              formControlName="name"
                            />
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell--span-4">
                          <mat-form-field
                            class="input-text-name-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Sobrenome</mat-label>
                            <input
                              class=""
                              matInput
                              type="text"
                              formControlName="surname"
                            />
                          </mat-form-field>
                        </div>
                        <div class="mdc-layout-grid__cell--span-4">
                          <mat-form-field
                            class="input-text-name-filter mat-form-field-flex w-100"
                          >
                            <mat-label>E-mail</mat-label>
                            <input
                              class=""
                              matInput
                              type="text"
                              formControlName="email"
                            />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="mdc-layout-grid__cell--span-6">
                <h3>Perfis e Instituições</h3>
                <mat-card>
                  <mat-card-content>
                    <p>Busque as instituições correspondentes ao usuário e em seguida defina seu perfil para cada uma delas:</p>
                    <div class="mdc-layout-grid destaque">
                      <div class="mdc-layout-grid__inner">
                        <div
                          class="mdc-layout-grid__cell--span-11"
                          [formGroup]="fieldSearch"
                        >
                          <mat-form-field
                            class="input-text-name-filter mat-form-field-flex w-100"
                          >
                            <mat-label>Buscar Instituições</mat-label>
                            <input
                              class=""
                              matInput
                              type="text"
                              formControlName="name"
                              [matAutocomplete]="auto"
                            />
                            <mat-autocomplete
                              #auto="matAutocomplete"
                              [displayWith]="displayFn"
                            >
                              @for (customer of listCustomers$ | async; track
                              customer) {
                              <mat-option
                                [value]="customer"
                                (onSelectionChange)="
                                  setSelectedCustomer(customer)
                                "
                              >
                                {{ customer.name }}
                              </mat-option>
                              }
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <br/>
                    <h4>*Não existem perfil e instituição cadastradas.</h4>
                    <h4>Perfis e Instituições Cadastradas:</h4>
                    <table mat-table class="w-100">
                      <thead>
                        <th></th>
                        <th></th>
                        <th></th>
                      </thead>
                      @for(customer of customers$ | async; track customer) {
                      <tr>
                        <td>
                          <mat-form-field
                          class="select-status-filter mat-form-field-flex w-100 no-bottom"
                          >
                            <mat-label>Instituição</mat-label>
                            <input
                              class=""
                              matInput
                              type="text"
                              value="{{ customer.name }}"
                              disabled="true"
                            />
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field
                            class="select-status-filter mat-form-field-flex w-100 no-bottom"
                          >
                            <mat-label>Perfil</mat-label>
                            <mat-select [(value)]="customer.role">
                              <mat-option>Selecione</mat-option>
                              @for (role of listRoles; track role) {
                              <mat-option
                                [value]="role"
                                (click)="updatePermission(customer.id, role)"
                                >{{ role }}</mat-option
                              >
                              }
                            </mat-select>
                          </mat-form-field>
                        </td>
                        <td>
                          <a
                            mat-mini-fab
                            matTooltip="Excluir"
                            matTooltipPosition="above"
                            target="_blank"
                            color="primary"
                            (click)="deleteSelectedCustomer(customer.id!)"
                          >
                            <mat-icon class="material-symbols-outlined"
                              >delete</mat-icon
                            >
                          </a>
                        </td>
                      </tr>
                    }
                    </table>
 
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
            <div class="barra-botao-acao-1">
              <button
                mat-fab
                extended
                color="surface"
                class="md-medium"
                [routerLink]="['/admin/user']"
              >
                Voltar
                <mat-icon class="material-symbols-outlined">reply</mat-icon>
              </button>
              <button
                mat-fab
                extended
                color="primary"
                class="md-medium"
                disabled="true"
              >
                Excluir
                <mat-icon class="material-symbols-outlined"
                  >delete</mat-icon
                >
              </button>
              <button
                mat-fab
                extended
                color="primary"
                class="md-medium"
                (click)="onSubmit()"
              >
                Salvar
                <mat-icon class="material-symbols-outlined">check</mat-icon>
              </button>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
