import { Component, OnInit, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardMenuComponent } from "../../../dashboard/dashboard-menu/dashboard-menu.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatOptionModule } from "@angular/material/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDialog } from "@angular/material/dialog";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ConfirmDialogComponent } from "../../../../shared/modal/confirm-dialog/confirm-dialog.component";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { AlertService } from "../../../../services/alert.service";
import { UserService } from "../../../../services/user.service";
import { CustomerService } from "../../../../services/customer.service";
import { User } from "../../../../model/user";
import { CustomerRelated } from "../../../../model/customer_related";
import { Customer } from "../../../../model/customer";
import { Pageable } from "../../../../model/pageable";
import { Page } from "../../../../model/page";
import { Router, RouterOutlet, RouterLink, RouterLinkActive } from "@angular/router";
import { Observable, of } from "rxjs";

interface customerSearchFilter {
  name: string;
}

@Component({
  selector: "app-user-create",
  standalone: true,
  imports: [
    CommonModule,
    DashboardMenuComponent,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    RouterLink,
    RouterOutlet
  ],
  templateUrl: "./user-create.component.html",
  styleUrl: "./user-create.component.scss",
})
export class UserCreateComponent implements OnInit {
  isDisabled: boolean = false;
  form: FormGroup;
  userService = inject(UserService);
  alertService = inject(AlertService);
  router = inject(Router);
  customerService = inject(CustomerService);

  //customers related
  customers: CustomerRelated[] = [];
  //search customer
  listCustomers$: Observable<Customer[]> = of();
  page: Page<Customer> | undefined;
  selectedCustomer: Customer | undefined;

  fieldSearch = new FormGroup({
    name: new FormControl<string>(""),
  });

  listRoles = [
    "ADMINISTRADOR",
    "ATENDENTE",
    "COORDENADOR_MEDICO",
    "COORDENADOR_TECNICO",
    "USER",
  ];

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      surname: [null, Validators.required],
      email: [null, Validators.required],
      identity: [null],
      roles: [null],
    });
  }

  ngOnInit() {

    this.fieldSearch.valueChanges.subscribe((searchFilter) => {
      let pageable: Pageable = {
        sort: "name,desc",
        offset: 1,
        limit: 10,
      };
      this.customerService
        .search(searchFilter as customerSearchFilter, pageable)
        .subscribe({
          next: (page: Page<Customer>) => {
            //this.searchForm.enable();
            this.page = page;
            if (this.page != null) {
              this.listCustomers$ = of(this.page.content);
              console.log(this.page.content);
            } else {
              this.listCustomers$ = of();
            }
          },
          error: (e) => {
            console.error(e);
          },
          complete: () => {
            //console.info("complete");
          },
        });
    });

  }

  onSubmit(): void {
    if (this.form.invalid) {
      console.log("invalido");
      console.log(this.form);
      return;
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: "350px",
        data: { message: "Tem certeza que deseja criar um novo usuário?" },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const formData = this.form.value; // Get form data
          this.userService.create(formData).subscribe(
            (createdUser) => {
              this.alertService.openSnackBar('Usuário Salvo com sucesso!');
              let pageable: Pageable = {
                sort: "name,desc",
                offset: 1,
                limit: 1,
              };
              let filter = { name: `"${this.form.get('email')?.value}"` }
              this.userService.search(filter, pageable).subscribe({
                next: (page: Page<User>) => {
                  if (page != null) {
                    let user = page.content[0];
                    console.log(user);

                    this.userService
                      .saveUserCustomers(user, this.customers).subscribe(
                        (customers) => {
                          this.alertService.openSnackBar("Salvo com sucesso!");
                          console.log(customers);
                          this.router.navigate(['/admin/user']);
                        },
                        (error) => {
                          this.alertService.openSnackBar(
                            "Um erro ocorreu ao tentar salvar."
                          );
                          console.log("error");
                          console.error(error);
                        }
                      );
                  }
                }
              });
              console.log(createdUser);
            },
            (error) => {
              this.alertService.openSnackBar('Um erro ocorreu ao tentar salvar.');
              console.log("error");
              console.error(error);
            }
          );
        }
      });
    }
  }
  //search customer block
  displayFn(customer: Customer): string {
    return "";
  }

  setSelectedCustomer(customer: Customer) {
    if (customer != undefined) {
      let item = {
        name: customer.name,
        id: customer.id,
        role: 'USER'
      } as CustomerRelated;
      let exists = this.customers.some((c) => customer.id == c.id)
      console.log(exists);
      if(!exists){
        this.customers?.push(item);
      }
    }
  }

  deleteSelectedCustomer(customerId: string) {
    if (customerId == undefined) {
      console.log("invalido");
      return;
    } else {
      this.customers = this.customers.filter((customer) => { return customerId != customer.id });
    }
  }
}
