<h2>Prontuário</h2>
<mat-dialog-content class="mat-typography modal">

  <div class="em-linha head-modal">
    <div class="item-resultado divisoria-ver w-40">
      <label>Paciente</label>
      <div class="body2">João Renato Teixeira Cruz</div>
      <div class="caption">59 anos e 04 meses | Feminino</div>
    </div>
    <div class="item-resultado divisoria-ver w-15">
      <label>&nbsp;</label>
      <div class="body0" align="center">MR</div>
      <div class="caption" align="center">Joelho Dir</div>
    </div>
    <div class="item-resultado divisoria-ver w-35">
      <label>Médico solicitante</label>
      <div class="body2">Dr. José Carlos de Almeida</div>
      <label>Instituição</label>
      <div class="body2">Hosp Hevenice Rocha</div>
    </div>
    <div class="item-resultado w-30">
      <label>Data/Hora do Exame</label>
      <div class="body2">20/03/2024 | 03:00</div>
      <label>Prazo do Exame</label>
      <div class="body2 status-prazo-ok">20/03/2024 | 03:50</div>
    </div>
  </div>
  <h3>Comentários do Exame</h3>
  <table mat-table class="tabela-modal w-100">
    <thead>
      <th>Comentário</th>
      <th>Usuário Responsável</th>
      <th>Data da Criação</th>
    </thead>
    <tr>
      <td class="w-50">Repetir perfil com todas as vertebras aparecendo. inclusive sua parte posterior</td>
      <td>
        Matias Pereira de Arruda
      </td>
      <td>20/03/2024 | 03:00</td>
    </tr>
    <tr>
      <td class="w-50">Paciente sentindo dores</td>
      <td>
        Aislan Rodrigues de Alencar
      </td>
      <td>20/03/2024 | 03:50</td>
    </tr>
  </table>
  <h3>Arquivos de Prontuário</h3>
  <table mat-table class="tabela-modal w-100">
    <thead>
      <th>Nome</th>
      <th>Responsável</th>
      <th>Data do Upload</th>
      <th>Arquivo</th>
    </thead>
    <tr>
      <td>Repetir perfil com todas as vertebras aparecendo. inclusive sua parte posterior</td>
      <td>Josias Quental de Alcântara</td>
      <td>20/03/2024 | 03:00</td>
      <td>
        <button mat-mini-fab mat-mini-fab matTooltip="Visualizar" matTooltipPosition="above" color="primary">
          <mat-icon class="material-symbols-outlined">visibility</mat-icon>
      </button>
      </td>
    </tr>
  </table>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-fab extended color="surface" [mat-dialog-close]="true" cdkFocusInitial>
    Fechar
    <mat-icon class="material-symbols-outlined">close</mat-icon>
  </button>
</mat-dialog-actions>