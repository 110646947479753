<div class="menu">
    <app-dashboard-menu></app-dashboard-menu>
  </div>
  <div class="mdc-layout-grid">
    <div class="mdc-layout-grid__inner">
      <img class="logo" src="/assets/logotipo.png" alt="logo" />
      <div class="mdc-layout-grid__cell--span-12">
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Instituição">

              <div class="barra-lateral-dir">
                <div class="box-barra-dir">
                    <button
                      mat-mini-fab
                      matTooltip="Voltar"
                      matTooltipPosition="left"
                      color="primary"
                      [routerLink]="['/']"
                    >
                      <mat-icon class="material-symbols-outlined">reply</mat-icon>
                    </button>
                    <button
                      mat-mini-fab
                      matTooltip="Adicionar"
                      matTooltipPosition="left"
                      color="primary"
                      [routerLink]="['./new']"
                    >
                      <mat-icon class="material-symbols-outlined">add</mat-icon>
                    </button>
                    <button
                      mat-mini-fab
                      matTooltip="Limpar Pesquisa"
                      matTooltipPosition="left"
                      color="primary"
                      disabled="true"
                    >
                      <mat-icon class="material-symbols-outlined">cleaning_services</mat-icon>
                    </button>
                  </div>

              </div>
              <div class="mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell--span-12">
                  <mat-card class="filter-card">
                    <mat-card-content>
                      <h3>Pesquisar</h3>
                      <form [formGroup]="searchForm">
                      <div class="mdc-layout-grid">
                        <div class="mdc-layout-grid__inner">
                            <div class="mdc-layout-grid__cell--span-3">
                                <mat-form-field
                                class="input-text-name-filter mat-form-field-flex w-100"
                                >
                                <mat-label>Instituição</mat-label>
                                <input
                                class=""
                                matInput
                                type="text"
                                formControlName="name"
                                />
                                @if (searchForm.controls['name']) {
                                <button
                                matSuffix
                                mat-icon-button
                                aria-label="Clear"
                                (click)="searchForm.controls['name'].setValue('')"
                                >
                                <mat-icon class="material-symbols-outlined"
                                    >cancel</mat-icon
                                >
                                </button>
                                }
                            </mat-form-field>
                            </div>
                        </div>
                      </div>
                      </form>
                    </mat-card-content>
                  </mat-card>
                  <h4>Resultado da Pesquisa</h4>
                  @for (customer of customers$ | async; track customer) {
                    <mat-card
                    class="results"
                    >
                  <mat-card-content>
                    <div class="w-100 em-linha">
                      <div class="item-resultado divisoria-ver w-20">
                        <label>Instituição</label>
                        <div class="body2">
                          {{ customer.name }}
                        </div>
                      </div>
                      <div class="item-resultado divisoria-ver w-15">
                        <label>Cidade</label>
                        <div class="body2">
                          {{ customer.address?.city }}
                        </div>
                      </div>
                      <div class="item-resultado divisoria-ver w-15">
                        <label>Grupo</label>
                        <div class="body2">
                          ------
                        </div>
                      </div>
                      <div class="item-resultado divisoria-ver w-15">
                        <label>Contato</label>
                        <div class="body2">
                          {{ customer.manager.name }}
                        </div>
                      </div>
                      <div class="item-resultado divisoria-ver">
                        <label>CNPJ</label>
                        <div class="body2">
                         {{ customer.document }}
                        </div>
                      </div>
                      <div class="item-resultado">
                        <div class="acoes-botoes">
                          <button
                            mat-mini-fab
                            matTooltip="Editar"
                            matTooltipPosition="above"
                            color="primary"
                            [routerLink]="['/admin/customer/', customer.id, 'edit']"
                          >
                            <mat-icon class="material-symbols-outlined">border_color</mat-icon>
                          </button>
                          <a
                            mat-mini-fab
                            matTooltip="Excluir"
                            matTooltipPosition="above"
                            target="_blank"
                            color="primary"
                            (click)="deleteCustomer(customer.id!)"
                          >
                            <mat-icon class="material-symbols-outlined">delete</mat-icon>
                          </a>

                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
                }
                </div>
            </div>

          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>

