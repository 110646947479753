import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

export abstract class BaseService<K> {
  protected constructor(protected http: HttpClient) { }
  /**
   * Get
   */
  protected get<K>(
    endpoint: string,
    params: HttpParams = new HttpParams(),
    options?: { headers?: HttpHeaders},
  ): Observable<K> {
    const headers =
      options && options.headers ? options.headers : this.defaultHeaders();

    return this.http.get(endpoint, {
      headers,
      params,
    }) as Observable<K>;
  }

  protected getBlob<K>(
    endpoint: string,
    params: HttpParams = new HttpParams(),
    options?: { headers?: HttpHeaders}
  ): Observable<K> {
    const headers =
      options && options.headers ? options.headers : this.defaultHeaders();

    return this.http.get(endpoint, {
      headers,
      responseType: 'blob',
      params,
    }) as Observable<K>;
  }
  /**
   *  Post
   */
  protected post<K>(
    endpoint: string,
    item: K,
    params: HttpParams = new HttpParams(),
    options?: { headers?: HttpHeaders }
  ): Observable<K> {
    const headers =
      options && options.headers ? options.headers : this.defaultHeaders();

    return this.http.post(endpoint, item, {
      headers,
      params,
    }) as Observable<K>;
  }
  /**
   * Delete
   */
  protected delete<K>(
    endpoint: string,
    options?: { headers?: HttpHeaders }
  ): Observable<K> {
    const headers =
      options && options.headers ? options.headers : this.defaultHeaders();

    return this.http.delete(endpoint, {
      headers,
    }) as Observable<K>;
  }
  /**
   * Put
   */
  protected put<K>(
    endpoint: string,
    item: K,
    params: HttpParams = new HttpParams(),
    options?: { headers?: HttpHeaders }
  ): Observable<K> {
    const headers =
      options && options.headers ? options.headers : this.defaultHeaders();

    return this.http.put(endpoint, item, {
      headers,
      params,
    }) as Observable<K>;
  }

  private defaultHeaders(): HttpHeaders {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json");
    //.set("Content-Type", "application/x-www-form-urlencoded");
    return headers;
  }
}
