import {
  Directive,
  inject,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { KeycloakService } from "keycloak-angular";

@Directive({
  selector: "[isGranted]",
})
export class IsGrantedDirective {
  private _keycloak: KeycloakService = inject(KeycloakService);
  private _templateRef = inject(TemplateRef);
  private _viewContainer = inject(ViewContainerRef);
  private _role!: string[];

  @Input()
  set isGranted(role: string[]) {
    this._role = role;
  }

  ngOnInit() {
    this._role.forEach((item) => {
      if (this._keycloak.getUserRoles(true).includes(item)) {
        this._viewContainer.clear();
        this._viewContainer.createEmbeddedView(this._templateRef);
      }
    });
  }
}
