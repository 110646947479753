import { Component, ViewChild, OnInit, inject } from "@angular/core";
import { CustomerService } from "../../../../services/customer.service";
import { AlertService } from "../../../../services/alert.service";
import { Customer } from "../../../../model/customer";
import { Pageable } from "../../../../model/pageable";
import { Page } from "../../../../model/page";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Observable, of } from "rxjs";
import { CommonModule } from "@angular/common";
import { DashboardMenuComponent } from "../../../dashboard/dashboard-menu/dashboard-menu.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatOptionModule } from "@angular/material/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../../../../shared/modal/confirm-dialog/confirm-dialog.component";
import { RouterOutlet, RouterLink, RouterLinkActive } from "@angular/router";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";

interface customerSearchFilter {
  name: string;
}

@Component({
  selector: "app-customer",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DashboardMenuComponent,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    CommonModule,
  ],
  templateUrl: "./customer-search.component.html",
  styleUrl: "./customer-search.component.scss",
})
export class CustomerSearchComponent implements OnInit {
  @ViewChild(MatPaginator) private _paginator: MatPaginator | undefined;
  @ViewChild(MatSort) private _sort: MatSort | undefined;

  customerService = inject(CustomerService);
  customers$: Observable<Customer[]> = of();

  alertService = inject(AlertService);
  dialog = inject(MatDialog);

  isDisabled: boolean = false;
  searchForm = new FormGroup({
    name: new FormControl<string>(""),
  });
  offset: number = 1;
  limit: number = 10;
  page: Page<Customer> | undefined;

  ngOnInit() {
    let pageable: Pageable = {
      sort: "name,desc",
      offset: 1,
      limit: 10,
    };
    this.searchForm.valueChanges.subscribe((searchFilter) => {
      let pageable: Pageable = {
        sort: "name,desc",
        offset: this.offset,
        limit: this.limit,
      };
      if (!this.isDisabled) {
        this.isDisabled = true;
        //this.searchForm.disable();
        this.customerService
          .search(searchFilter as customerSearchFilter, pageable)
          .subscribe({
            next: (page: Page<Customer>) => {
              //this.searchForm.enable();
              if (this._paginator) {
                this._paginator.length = page.total;
              }
              this.page = page;

              if (this.page != null) {
                this.customers$ = of(this.page.content);
              } else {
                this.customers$ = of();
              }
              console.log(page);
            },
            error: (e) => {
              console.error(e);
              //this.searchForm.enable();
              this.isDisabled = false;
            },
            complete: () => {
              console.info("complete");
              //this.searchForm.enable();
              this.isDisabled = false;
            },
          });
      }
    });
  }

  deleteCustomer(id: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "250px",
      data: { message: "Tem certeza que deseja apagar esta instituição?" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customerService.remove(id).subscribe(
          () => {
            this.searchForm.updateValueAndValidity();
            this.alertService.openSnackBar('Apagado com sucesso!');
          },
          error => {
            console.log('erro ao tentar deletar');
          }
        );
      }
    });
  }
  //this.customerService.search(pageable).subscribe({
  //  next: (page: Page<Customer>) => {
  //      console.log(page.content);
  //  },
  //  error: (e) => {
  //    console.log(e);
  //  }
  //});
}
//  this.form = this.formBuilder.group({
//    name: [null, Validators.required],
//    description: [null],
//    document: [null],
//    roles: [null],
//    address: this.formBuilder.group({
//      street: [null],
//      city: [null],
//      state: [null],
//      zipCode: [null],
//    }),
//    owner: this.formBuilder.group({
//      name: [null],
//      email: [null],
//      telephone: [null],
//    }),
//    manager: this.formBuilder.group({
//      name: [null],
//      email: [null],
//      document: [null],
//      telephone: [null],
//    }),
//    sla: this.formBuilder.group({
//      hospitalized: [null],
//      emergency: [null],
//      urgency: [null],
//      routine: [null],
//    }),
//    bucketLifecyclePolicy: [null],
//  });
//}
